import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty, round } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityResponse, QueueStatisticsResponse, SalesStatisticsDetailsSummaryResponse } from '@api'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import { useNotify } from '@components/notifications/NotificationsContext'
import { parseDate } from '@components/plots/common'

interface ExportButtonProps {
    selectedLocality: LocalityResponse
    pickedInterval: Interval
    queueData: QueueStatisticsResponse
    salesDetails: SalesStatisticsDetailsSummaryResponse
}

export const QueueDetailsExportButton: React.FC<ExportButtonProps> = ({
    selectedLocality,
    pickedInterval,
    queueData,
    salesDetails,
}) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()
    const notify = useNotify()

    let localityQueuesData: ExportData = {}
    let localityBreakdownSalesData: ExportData = {}

    if (!isEmpty(queueData.items)) {
        localityQueuesData = {
            [t('heading.localityQueueStatistics', '{{locality}} Queues Statistics', {
                locality: selectedLocality.name,
            })]: queueData.items.map((item) => ({
                [t('others.time', 'Time')]: localize(parseDate(item.startingFrom)).toLocaleString({
                    ...DateTime.DATETIME_SHORT,
                    weekday: 'short',
                }),
                [t('statistics.posInteractions', 'POS interactions')]: item.serveTimeSampleCount ?? 0,
                [t('statistics.avgWaitTimeWithUnit', 'Average wait time {{ unit }}', {
                    unit: t('units.secondsShort', '(s)'),
                })]: round(item.waitTimeAverage ?? 0),
                [t('statistics.avgServeTimeWithUnit', 'Average serve time {{ unit }}', {
                    unit: t('units.secondsShort', '(s)'),
                })]: round(item.serveTimeAverage ?? 0),
                [t('statistics.maxWaitTimeWithUnit', 'Max. wait time {{ unit }}', {
                    unit: t('units.secondsShort', '(s)'),
                })]: round(item.waitTimeMax ?? 0),
                [t('statistics.maxServeTimeWithUnit', 'Max. serve time {{ unit }}', {
                    unit: t('units.secondsShort', '(s)'),
                })]: round(item.serveTimeMax ?? 0),
            })),
        }
    }

    if (!isEmpty(salesDetails.zoneRows) && !isEmpty(salesDetails.timeBrackets)) {
        localityBreakdownSalesData = {
            [t('statistics.localityPosBreakdown', '{{locality}} PoS Breakdown', {
                locality: selectedLocality.name,
            })]: salesDetails.timeBrackets.map((bracket, i) => {
                const row = {
                    [t('others.time', 'Time')]: localize(parseDate(bracket.startingFrom)).toLocaleString({
                        ...DateTime.DATETIME_SHORT,
                        weekday: 'short',
                    }),
                }

                salesDetails.zoneRows.forEach((dataRow) => {
                    Object.assign(row, {
                        [t('statistics.salesZone', '{{zone}} - Sales', {
                            zone: dataRow.zoneName,
                        })]: dataRow.serveTimeSampleCount[i],
                        [t('statistics.avgZoneServeTimeWithUnit', '{{zone}} Average serve time {{ unit }}', {
                            zone: dataRow.zoneName,
                            unit: t('units.secondsShort', '(s)'),
                        })]: round(dataRow.serveTimeAverage[i] ?? 0),
                        [t('statistics.maxZoneServeTimeWithUnit', '{{zone}} Max. serve time {{ unit }}', {
                            zone: dataRow.zoneName,
                            unit: t('units.secondsShort', '(s)'),
                        })]: round(dataRow.serveTimeMax[i] ?? 0),
                    })
                })

                return row
            }),
        }
    }

    const handleDownload = () => {
        const exportData = {
            ...localityQueuesData,
            ...localityBreakdownSalesData,
        }

        if (!isEmpty(exportData)) {
            exportToExcel(
                exportData,
                `${selectedLocality.name}-queue-details-${formatIntervalToFilename(
                    gdynusaReadableInterval(pickedInterval.mapEndpoints(localize))
                )}`,
                GoogleEventName.DOWNLOAD_QUEUES_LOCALITY_EXCEL
            )
        } else {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.nothingToExport', 'Nothing to export'),
                variant: 'danger',
            })
        }
    }

    return (
        <Button variant="secondary" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} />
            {t('button.downloadExcel', 'Download Excel')}
        </Button>
    )
}
