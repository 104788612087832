import { faChevronDown, faVectorSquare } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import produce from 'immer'
import { Dictionary, flatten, isEmpty, isEqual, mapValues, omit, partial, pick, remove, uniqueId, zip } from 'lodash'
import React, { useState } from 'react'
import { Accordion, Button, Card, useAccordionToggle } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { useKey } from 'react-use'
import AutoSizer from 'react-virtualized-auto-sizer'

import { LocalityResponse, OrganizationResponse, Role, SceneDescription, SceneResponse } from '@api/models'

import { localityApi, sceneApi } from '@services'

import {
    EditableSceneObject,
    isSceneObjectEqual,
    sceneObjectBackendType,
    SceneObjectBackendType,
    SceneObjectIdentifier,
    SceneObjectType,
} from '@helpers/describeScene'
import useProfile from '@helpers/profile'
import { defaultErrorEntry } from '@helpers/types'
import { pickColumn } from '@helpers/utils'

import Box from '@elements/Box/Box'

import AsyncButton from '@components/AsyncButton'
import DescribeSceneEditor from '@components/DeviceSceneConfiguration/DescribeSceneEditor'
import FeatureChecker from '@components/FeatureChecker'
import SideNavigationListLayout from '@components/Layouts/SideNavigationLayout'
import SpacedLayout from '@components/Layouts/SpacedLayout'
import LocalityModulesForm, {
    emptyConversionsConfiguration,
    emptyFootfallConfiguration,
    emptyOccupancyConfiguration,
    emptyQueuesConfiguration,
    emptyStaySafeConfiguration,
    LocalityModulesConfiguration,
    LocalityModulesErrors,
} from '@components/LocalityModulesForm'
import RoleChecker from '@components/RoleChecker'
import { useNotify } from '@components/notifications/NotificationsContext'

import { ReactComponent as BoundaryIcon } from '@images/icons/boundary_line.svg'

import DescribeSceneNestedList from './DescribeSceneNestedList'
import styles from './DescribeSceneTab.module.scss'

type SceneObjectWithoutType = Omit<EditableSceneObject, 'type'> & SceneObjectIdentifier
type SceneObjectWithoutTypeAndVisibility = Omit<SceneObjectWithoutType, 'isVisible'>

type DescribeSceneConfigurationItem = {
    visitBoundaries: Array<SceneObjectWithoutType>
    detectionZones: Array<SceneObjectWithoutType>
    stopZones: Array<SceneObjectWithoutType>
}

type DescribeSceneConfigurationItemWithoutVisibility = {
    visitBoundaries: Array<SceneObjectWithoutTypeAndVisibility>
    detectionZones: Array<SceneObjectWithoutTypeAndVisibility>
    stopZones: Array<SceneObjectWithoutTypeAndVisibility>
}

type DescribeSceneConfiguration = {
    [deviceId: string]: DescribeSceneConfigurationItem
}

type DescribeSceneConfigurationWithoutVisibility = {
    [deviceId: string]: DescribeSceneConfigurationItemWithoutVisibility
}

const ToggleHeader: React.FC<{
    children: React.ReactNode
    eventKey: string
    onClick?: () => void
}> = ({ children, eventKey, onClick }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => onClick?.())

    return (
        <div className={styles.accordionToggleStyles} onClick={decoratedOnClick}>
            {children}
        </div>
    )
}

const stripVisibilityValues = (sceneObjects: DescribeSceneConfiguration): DescribeSceneConfigurationWithoutVisibility =>
    mapValues(
        sceneObjects,
        (sceneConfig) =>
            mapValues(sceneConfig, (record, key) => {
                if (key === 'visitBoundaries' || key === 'detectionZones' || key === 'stopZones') {
                    const sceneObject = record as Array<SceneObjectWithoutTypeAndVisibility>

                    return !isEmpty(sceneObject)
                        ? sceneObject.map((item) => omit(item, 'isVisible', 'type'))
                        : sceneObject
                }

                return record
            }) as DescribeSceneConfigurationItemWithoutVisibility
    )

const editorStateToSceneObjects = (
    modulesConfiguration: LocalityModulesConfiguration,
    sceneConfiguration: DescribeSceneConfigurationItem
): Array<EditableSceneObject> =>
    flatten([sceneConfiguration.visitBoundaries, sceneConfiguration.detectionZones, sceneConfiguration.stopZones]).map(
        (sceneObject) => {
            const matches = partial(isSceneObjectEqual, sceneObject)
            const type: SceneObjectType = (() => {
                switch (sceneObject.backendType) {
                    case SceneObjectBackendType.visitBoundaries:
                        if (
                            modulesConfiguration.footfallConfiguration?.footfallBoundaries.some(matches) ||
                            modulesConfiguration.staySafeConfiguration?.staySafeBoundaries.some(matches) ||
                            modulesConfiguration.conversionsConfiguration?.visitorBoundaries.some(matches)
                        ) {
                            return SceneObjectType.entrance
                        } else if (modulesConfiguration.conversionsConfiguration?.passerByBoundaries.some(matches)) {
                            return SceneObjectType.passage
                        }

                        return SceneObjectType.otherBoundary
                    case SceneObjectBackendType.detectionZones:
                        if (modulesConfiguration.queuesConfiguration?.salesZones.some(matches)) {
                            return SceneObjectType.pos
                        } else if (modulesConfiguration.queuesConfiguration?.queueZones.some(matches)) {
                            return SceneObjectType.queue
                        } else if (modulesConfiguration.occupancyConfiguration?.occupancyZones.some(matches)) {
                            return SceneObjectType.seat
                        }

                        return SceneObjectType.otherZone
                    case SceneObjectBackendType.stopZones:
                        return SceneObjectType.stopZone
                }
            })()

            return { ...sceneObject, type } as EditableSceneObject
        }
    )

const loadSceneConfigurations = (
    sceneConfigurations: Dictionary<SceneDescription>,
    scenes: SceneResponse[]
): DescribeSceneConfiguration =>
    mapValues(pick(sceneConfigurations, pickColumn(scenes, 'id')), (configuration) => ({
        visitBoundaries: configuration.visitBoundaries.map((boundary) => ({
            ...boundary,
            backendType: SceneObjectBackendType.visitBoundaries,
            isVisible: true,
        })),
        detectionZones: configuration.detectionZones.map((zone) => ({
            ...zone,
            backendType: SceneObjectBackendType.detectionZones,
            isVisible: true,
        })),
        stopZones: configuration.stopZones.map((zone) => ({
            ...zone,
            backendType: SceneObjectBackendType.stopZones,
            isVisible: true,
        })),
    }))

const loadModulesConfiguration = (locality: LocalityResponse): LocalityModulesConfiguration => ({
    capacity: locality.capacity,
    footfallConfiguration: locality.footfallConfiguration
        ? {
              ...locality.footfallConfiguration,
              footfallBoundaries: locality.footfallConfiguration.footfallBoundaries.map((id) => ({
                  id,
                  backendType: SceneObjectBackendType.visitBoundaries,
              })),
          }
        : undefined,
    conversionsConfiguration: locality.conversionsConfiguration
        ? {
              ...locality.conversionsConfiguration,
              passerByBoundaries: locality.conversionsConfiguration.passerByBoundaries.map((id) => ({
                  id,

                  backendType: SceneObjectBackendType.visitBoundaries,
              })),
              visitorBoundaries: locality.conversionsConfiguration.visitorBoundaries.map((id) => ({
                  id,
                  backendType: SceneObjectBackendType.visitBoundaries,
              })),
          }
        : undefined,
    queuesConfiguration: locality.queuesConfiguration
        ? {
              ...locality.queuesConfiguration,
              salesZones: locality.queuesConfiguration.salesZones.map((id) => ({
                  id,
                  backendType: SceneObjectBackendType.detectionZones,
              })),
              queueZones: locality.queuesConfiguration.queueZones.map((id) => ({
                  id,
                  backendType: SceneObjectBackendType.detectionZones,
              })),
          }
        : undefined,
    occupancyConfiguration: locality.occupancyConfiguration
        ? {
              ...locality.occupancyConfiguration,
              occupancyZones: locality.occupancyConfiguration.occupancyZones.map((id) => ({
                  id,
                  backendType: SceneObjectBackendType.detectionZones,
              })),
          }
        : undefined,
    emotionConfiguration: locality.emotionConfiguration
        ? {
              ...locality.emotionConfiguration,
              emotionZones: locality.emotionConfiguration.emotionZones.map((id) => ({
                  id,
                  backendType: SceneObjectBackendType.detectionZones,
              })),
          }
        : undefined,
    staySafeConfiguration: locality.staySafeConfiguration
        ? {
              ...locality.staySafeConfiguration,
              staySafeBoundaries: locality.staySafeConfiguration.staySafeBoundaries.map((id) => ({
                  id,
                  backendType: SceneObjectBackendType.visitBoundaries,
              })),
          }
        : undefined,
})

type DrawFunction = () => void

const TopButtonToolbar: React.FC<{
    onDrawEntranceStart: DrawFunction
    onDrawPassageStart: DrawFunction
    onDrawPosStart: DrawFunction
    onDrawQueueStart: DrawFunction
    onDrawSeatStart: DrawFunction
    organization: OrganizationResponse
}> = ({ onDrawEntranceStart, onDrawPassageStart, onDrawPosStart, onDrawQueueStart, onDrawSeatStart, organization }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.buttonToolbarContainer}>
            <div className={styles.boundaries}>
                <FeatureChecker
                    allowForAdmin={false}
                    features={['footfall', 'conversions', 'realtimeOccupancy']}
                    organization={organization}
                >
                    <button className={styles.entranceButton} onClick={onDrawEntranceStart}>
                        <BoundaryIcon />
                        {t('sceneConfiguration.entrance', 'Entrance')}
                    </button>
                </FeatureChecker>
                <FeatureChecker allowForAdmin={false} feature="conversions" organization={organization}>
                    <button className={styles.passageButton} onClick={onDrawPassageStart}>
                        <BoundaryIcon />
                        {t('sceneConfiguration.passage', 'Passage')}
                    </button>
                </FeatureChecker>
            </div>
            <div className={styles.zones}>
                <FeatureChecker
                    allowForAdmin={false}
                    features={['conversions', 'queueMonitoring']}
                    organization={organization}
                >
                    <button className={styles.posButton} onClick={onDrawPosStart}>
                        <FontAwesomeIcon icon={faVectorSquare} />
                        {t('sceneConfiguration.pos', 'POS')}
                    </button>
                </FeatureChecker>
                <FeatureChecker allowForAdmin={false} feature="queueMonitoring" organization={organization}>
                    <button className={styles.queueButton} onClick={onDrawQueueStart}>
                        <FontAwesomeIcon icon={faVectorSquare} />
                        {t('sceneConfiguration.queue', 'Queue')}
                    </button>
                </FeatureChecker>
                <FeatureChecker allowForAdmin={false} feature="zoneOccupancy" organization={organization}>
                    <button className={styles.seatButton} onClick={onDrawSeatStart}>
                        <FontAwesomeIcon icon={faVectorSquare} />
                        {t('sceneConfiguration.seat', 'Seat')}
                    </button>
                </FeatureChecker>
            </div>
        </div>
    )
}

const BottomButtonToolbar: React.FC<{ onDrawStopZoneStart: DrawFunction }> = ({ onDrawStopZoneStart }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.buttonToolbarContainer}>
            <div className={styles.zones}>
                <button className={styles.stopZoneButton} onClick={onDrawStopZoneStart}>
                    <FontAwesomeIcon icon={faVectorSquare} />

                    {t('sceneConfiguration.stopZone', 'Ignore')}
                </button>
            </div>
        </div>
    )
}

export const determineSelectedSceneObject =
    (comparedAgainst: SceneObjectIdentifier) =>
    (comparedObject?: SceneObjectIdentifier): boolean =>
        comparedObject !== undefined && isSceneObjectEqual(comparedObject, comparedAgainst)

const DescribeSceneTab: React.FC<{
    scenes: Array<SceneResponse>
    sceneDescriptions: Dictionary<SceneDescription>
    locality: LocalityResponse
    organization: OrganizationResponse
    determineSelectScenePath: (sceneId: number) => string
    onFinished: () => void
    picker?: JSX.Element
}> = ({ scenes, sceneDescriptions, locality, organization, determineSelectScenePath, onFinished, picker }) => {
    const profile = useProfile()
    const { t } = useTranslation()
    const notify = useNotify()
    const history = useHistory()
    const queryClient = useQueryClient()

    const { mutateAsync: updateSceneConfiguration } = useMutation(sceneApi.updateSceneDescription)
    const { mutateAsync: updateLocality } = useMutation(localityApi.updateLocality)

    const [collapsed, setCollapsed] = useState(true)

    const params = useParams<{ sceneId: string }>()

    const selectedSceneId = params.sceneId

    const [selectedSceneObject, setSelectedSceneObject] = useState<SceneObjectIdentifier | undefined>(undefined)
    const [highlightedSceneObject, setHighlightedSceneObject] = useState<SceneObjectIdentifier | undefined>(undefined)

    const [sceneConfigurations, setSceneConfigurations] = useState<DescribeSceneConfiguration>(
        loadSceneConfigurations(sceneDescriptions, scenes)
    )

    const [modulesConfiguration, setModulesConfiguration] = useState<LocalityModulesConfiguration>(
        loadModulesConfiguration(locality)
    )

    const [modulesErrors, setModulesErrors] = useState<LocalityModulesErrors>({
        capacity: defaultErrorEntry,
        bounceThreshold: defaultErrorEntry,
        minBreakDuration: defaultErrorEntry,
        minSessionDuration: defaultErrorEntry,
    })

    const documentTitle = `${locality.name} - ${t('tab.describeScene', 'Describe scene')}`

    const handleCancel = () => {
        setSceneConfigurations(loadSceneConfigurations(sceneDescriptions, scenes))
        setModulesConfiguration(loadModulesConfiguration(locality))
        setModulesErrors({
            capacity: defaultErrorEntry,
            bounceThreshold: defaultErrorEntry,
            minBreakDuration: defaultErrorEntry,
            minSessionDuration: defaultErrorEntry,
        })
        setSelectedSceneObject(undefined)
    }

    const updater = async () => {
        const temporaryIdResolutions: Dictionary<number> = {}
        const resolveId = ({ id, tempId }: SceneObjectIdentifier): number =>
            id !== undefined ? id : temporaryIdResolutions[tempId!]

        const sceneUpdatePromises = Object.entries(sceneConfigurations).map(async ([sceneId, sceneConfiguration]) => {
            const updatedSceneConfiguration = await updateSceneConfiguration({
                sceneId: Number(sceneId),
                body: {
                    visitBoundaries: sceneConfiguration.visitBoundaries.map((b) => ({
                        name: b.name,
                        points: b.points,
                        id: b.id,
                    })),
                    detectionZones: sceneConfiguration.detectionZones.map((b) => ({
                        name: b.name,
                        points: b.points,
                        id: b.id,
                    })),
                    stopZones: sceneConfiguration.stopZones.map((b) => ({
                        name: b.name,
                        points: b.points,
                        id: b.id,
                    })),
                },
            })

            for (const [sent, returned] of zip(
                [...sceneConfiguration.visitBoundaries, ...sceneConfiguration.detectionZones],
                [...updatedSceneConfiguration.visitBoundaries, ...updatedSceneConfiguration.detectionZones]
            )) {
                // The update endpoint maintains scene object order in the response
                if (sent!.tempId !== undefined) {
                    temporaryIdResolutions[sent!.tempId] = returned!.id
                }
            }
        })

        await Promise.all(sceneUpdatePromises)

        await updateLocality({
            organizationId: organization.id,
            localityId: locality.id,
            body: {
                name: locality.name,
                labels: locality.labels,
                location: locality.location,
                capacity: modulesConfiguration.capacity,
                footfallConfiguration:
                    modulesConfiguration.footfallConfiguration !== undefined
                        ? {
                              ...modulesConfiguration.footfallConfiguration,
                              footfallBoundaries:
                                  modulesConfiguration.footfallConfiguration.footfallBoundaries.map(resolveId),
                          }
                        : undefined,
                staySafeConfiguration:
                    modulesConfiguration.staySafeConfiguration !== undefined
                        ? {
                              ...modulesConfiguration.staySafeConfiguration,
                              staySafeBoundaries:
                                  modulesConfiguration.staySafeConfiguration.staySafeBoundaries.map(resolveId),
                          }
                        : undefined,
                conversionsConfiguration:
                    modulesConfiguration.conversionsConfiguration !== undefined
                        ? {
                              ...modulesConfiguration.conversionsConfiguration,
                              visitorBoundaries:
                                  modulesConfiguration.conversionsConfiguration.visitorBoundaries.map(resolveId),
                              passerByBoundaries:
                                  modulesConfiguration.conversionsConfiguration.passerByBoundaries.map(resolveId),
                          }
                        : undefined,
                queuesConfiguration:
                    modulesConfiguration.queuesConfiguration !== undefined
                        ? {
                              ...modulesConfiguration.queuesConfiguration,
                              queueZones: modulesConfiguration.queuesConfiguration.queueZones.map(resolveId),
                              salesZones: modulesConfiguration.queuesConfiguration.salesZones.map(resolveId),
                          }
                        : undefined,
                occupancyConfiguration:
                    modulesConfiguration.occupancyConfiguration !== undefined
                        ? {
                              ...modulesConfiguration.occupancyConfiguration,
                              occupancyZones: modulesConfiguration.occupancyConfiguration.occupancyZones.map(resolveId),
                          }
                        : undefined,
                emotionConfiguration:
                    modulesConfiguration.emotionConfiguration !== undefined
                        ? {
                              ...modulesConfiguration.emotionConfiguration,
                              emotionZones: modulesConfiguration.emotionConfiguration.emotionZones.map(resolveId),
                          }
                        : undefined,
            },
        })
    }

    const { mutate: handleSubmit, status: updateState } = useMutation(updater, {
        onSuccess: () => {
            queryClient.invalidateQueries([sceneApi.name])
            queryClient.invalidateQueries([localityApi.name])

            onFinished()
            notify({
                title: t('notification.success'),
                content: t('notification.sceneConfigurationSuccess', 'Scene configuration updated successfully'),
                variant: 'success',
            })
        },
        onError: () => {
            notify({
                title: t('notification.error'),
                content: t(
                    'notification.sceneConfigurationError',
                    'There was an error during the scene configuration update'
                ),
                variant: 'danger',
            })
        },
    })

    const handleSubmitClick = () => {
        if (Object.values(modulesErrors).some((it) => it.isInvalid)) {
            notify({
                title: t('notification.error'),
                content: t('notification.invalidInputFields'),
                variant: 'warning',
            })

            return
        }

        handleSubmit()
    }

    const handleSelectScene = (id: string) => history.push(determineSelectScenePath(Number(id)))

    const handleSelectSceneObject = (deviceId: number, sceneObject: SceneObjectIdentifier) => {
        const isSelected = determineSelectedSceneObject(sceneObject)

        setSelectedSceneObject(
            flatten([
                sceneConfigurations[deviceId].visitBoundaries,
                sceneConfigurations[deviceId].detectionZones,
                sceneConfigurations[deviceId].stopZones,
            ]).find(isSelected)
        )

        if (deviceId !== Number(selectedSceneId)) {
            history.push(determineSelectScenePath(deviceId))
        }
    }

    const createDrawStartHandler = (type: SceneObjectType, name: string) => () => {
        const newSceneObject = {
            name,
            backendType: sceneObjectBackendType(type),
            type,
            tempId: uniqueId(),
            points: [],
            isVisible: true,
        }

        setSceneConfigurations((prevState) =>
            produce(prevState, (draft) => {
                draft[selectedSceneId][newSceneObject.backendType].push(newSceneObject as EditableSceneObject)
            })
        )

        setModulesConfiguration((prevState) =>
            produce(prevState, (draft) => {
                switch (type) {
                    case SceneObjectType.entrance:
                        draft.staySafeConfiguration = draft.staySafeConfiguration ?? emptyStaySafeConfiguration
                        draft.staySafeConfiguration.staySafeBoundaries.push(newSceneObject)

                        draft.conversionsConfiguration = draft.conversionsConfiguration ?? emptyConversionsConfiguration
                        draft.conversionsConfiguration.visitorBoundaries.push(newSceneObject)

                        draft.footfallConfiguration = draft.footfallConfiguration ?? emptyFootfallConfiguration
                        draft.footfallConfiguration.footfallBoundaries.push(newSceneObject)
                        break
                    case SceneObjectType.passage:
                        draft.conversionsConfiguration = draft.conversionsConfiguration ?? emptyConversionsConfiguration
                        draft.conversionsConfiguration.passerByBoundaries.push(newSceneObject)
                        break
                    case SceneObjectType.pos:
                        draft.queuesConfiguration = draft.queuesConfiguration ?? emptyQueuesConfiguration
                        draft.queuesConfiguration.salesZones.push(newSceneObject)
                        break
                    case SceneObjectType.queue:
                        draft.queuesConfiguration = draft.queuesConfiguration ?? emptyQueuesConfiguration
                        draft.queuesConfiguration.queueZones.push(newSceneObject)
                        break
                    case SceneObjectType.seat:
                        draft.occupancyConfiguration = draft.occupancyConfiguration ?? emptyOccupancyConfiguration
                        draft.occupancyConfiguration.occupancyZones.push(newSceneObject)
                        break
                }
            })
        )

        setSelectedSceneObject(newSceneObject)
    }

    const handleSceneObjectDelete = (sceneObject: SceneObjectIdentifier) => {
        const isSelected = determineSelectedSceneObject(sceneObject)

        setSceneConfigurations((prevState) =>
            produce(prevState, (draft) => {
                for (const collection of Object.values(draft)) {
                    remove(collection.visitBoundaries, isSelected)
                    remove(collection.detectionZones, isSelected)
                    remove(collection.stopZones, isSelected)
                }
            })
        )

        setModulesConfiguration((prevState) =>
            produce(prevState, (draft) => {
                for (const list of [
                    draft.footfallConfiguration?.footfallBoundaries,
                    draft.staySafeConfiguration?.staySafeBoundaries,
                    draft.conversionsConfiguration?.visitorBoundaries,
                    draft.conversionsConfiguration?.passerByBoundaries,
                    draft.queuesConfiguration?.queueZones,
                    draft.queuesConfiguration?.salesZones,
                    draft.occupancyConfiguration?.occupancyZones,
                    draft.emotionConfiguration?.emotionZones,
                ]) {
                    if (list === undefined) {
                        continue
                    }

                    remove(list, isSelected)
                }
            })
        )
    }

    useKey(
        'Delete',
        () => {
            if (selectedSceneObject) {
                handleSceneObjectDelete(selectedSceneObject)
            }
        },
        {},
        [selectedSceneObject]
    )

    const handleSceneObjectEdit = (deviceId: number, sceneObject: EditableSceneObject) => {
        const isSelected = determineSelectedSceneObject(sceneObject)

        setSceneConfigurations((prevState) =>
            produce(prevState, (draft) => {
                for (const item of draft[deviceId][sceneObject.backendType]) {
                    if (isSelected(item)) {
                        Object.assign(item, sceneObject)
                    }
                }
            })
        )
    }

    const handleFlipBoundary = (deviceId: number, boundary: SceneObjectIdentifier) =>
        setSceneConfigurations((prev) =>
            produce(prev, (draft) => {
                const editedBoundary = draft[deviceId]?.visitBoundaries.find(determineSelectedSceneObject(boundary))

                if (editedBoundary !== undefined) {
                    editedBoundary.points.reverse()
                }
            })
        )

    const selectedScene = scenes.find((scene) => String(scene.id) === selectedSceneId)
    const sceneObjects =
        selectedSceneId !== undefined && sceneConfigurations[selectedSceneId] !== undefined
            ? editorStateToSceneObjects(modulesConfiguration, sceneConfigurations[selectedSceneId]).filter(
                  ({ backendType }) => backendType !== 'stopZones' || profile.data?.role === Role.Administrator
              )
            : []

    const sceneUpdated =
        !isEqual(
            stripVisibilityValues(sceneConfigurations),
            stripVisibilityValues(loadSceneConfigurations(sceneDescriptions, scenes))
        ) || !isEqual(modulesConfiguration, loadModulesConfiguration(locality))

    const saveButtons = (
        <SpacedLayout
            className={classNames({
                [styles.saveButtonsContainerVisible]: sceneUpdated,
                [styles.saveButtonsContainerHidden]: !sceneUpdated,
            })}
            direction="horizontal"
            gapSize="sm"
        >
            <Button variant="secondary" onClick={handleCancel}>
                {t('button.cancel')}
            </Button>
            <AsyncButton
                allowRefetch={true}
                failedText={t('notification.error')}
                retryTimeoutSeconds={1}
                status={updateState}
                text={t('button.saveChanges', 'Save changes')}
                onClick={() => handleSubmitClick()}
            />
        </SpacedLayout>
    )

    const selectableBoundaries = Object.entries(sceneConfigurations)
        .filter(([id]) => pickColumn(scenes, 'id').includes(Number(id)))
        .flatMap(([sceneId, { visitBoundaries: boundaries }]) =>
            boundaries.map((b) => {
                const device = scenes.find(({ id }) => id === Number(sceneId))?.deviceId

                return {
                    ...b,
                    label: `${b.name} ${device ? `(#${device})` : ''}`,
                }
            })
        )

    const selectableZones = Object.entries(sceneConfigurations)
        .filter(([id]) => pickColumn(scenes, 'id').includes(Number(id)))
        .flatMap(([sceneId, { detectionZones: zones }]) =>
            zones.map((z) => {
                const device = scenes.find(({ id }) => id === Number(sceneId))?.deviceId

                return {
                    ...z,
                    label: `${z.name} ${device ? `(#${device})` : ''}`,
                }
            })
        )

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <Box paddingSize="lg">
                <SideNavigationListLayout
                    columnGap="sm"
                    content={
                        <SpacedLayout gapSize="xs">
                            <TopButtonToolbar
                                organization={organization}
                                onDrawEntranceStart={createDrawStartHandler(
                                    SceneObjectType.entrance,
                                    t('sceneConfiguration.entrance', 'Entrance')
                                )}
                                onDrawPassageStart={createDrawStartHandler(
                                    SceneObjectType.passage,
                                    t('sceneConfiguration.passage', 'Passage')
                                )}
                                onDrawPosStart={createDrawStartHandler(
                                    SceneObjectType.pos,
                                    t('sceneConfiguration.pos', 'POS')
                                )}
                                onDrawQueueStart={createDrawStartHandler(
                                    SceneObjectType.queue,
                                    t('sceneConfiguration.queue', 'Queue')
                                )}
                                onDrawSeatStart={createDrawStartHandler(
                                    SceneObjectType.seat,
                                    t('sceneConfiguration.seat', 'Seat')
                                )}
                            />
                            <div>
                                <AutoSizer disableHeight>
                                    {({ width }) => {
                                        return (
                                            <div style={{ width }}>
                                                {selectedScene && (
                                                    <DescribeSceneEditor
                                                        disableContentScaling={false}
                                                        highlightedObject={highlightedSceneObject}
                                                        scene={selectedScene}
                                                        sceneObjects={sceneObjects}
                                                        selectedObject={selectedSceneObject}
                                                        width={width}
                                                        onHighlight={setHighlightedSceneObject}
                                                        onObjectChanged={partial(
                                                            handleSceneObjectEdit,
                                                            selectedScene.id
                                                        )}
                                                        onSelect={setSelectedSceneObject}
                                                    />
                                                )}
                                            </div>
                                        )
                                    }}
                                </AutoSizer>
                            </div>
                            <RoleChecker whitelist={[Role.Administrator]}>
                                <BottomButtonToolbar
                                    onDrawStopZoneStart={createDrawStartHandler(
                                        SceneObjectType.stopZone,
                                        t('sceneConfiguration.stopZone', 'Ignore')
                                    )}
                                />
                            </RoleChecker>
                        </SpacedLayout>
                    }
                    contentHeading={{
                        heading: '',
                        headingButtons: saveButtons,
                    }}
                    listHeading={{
                        heading: t('tab.describeScene', 'Describe scene'),
                    }}
                    navigation={
                        <DescribeSceneNestedList
                            highlightedSceneObject={highlightedSceneObject}
                            picker={picker}
                            sceneConfigurations={mapValues(
                                sceneConfigurations,
                                partial(editorStateToSceneObjects, modulesConfiguration)
                            )}
                            scenes={scenes}
                            selectedScene={selectedScene}
                            selectedSceneObject={selectedSceneObject}
                            onDeleteSceneObject={(_, sceneObject) => handleSceneObjectDelete(sceneObject)}
                            onEditSceneObject={handleSceneObjectEdit}
                            onFlipBoundary={handleFlipBoundary}
                            onHighlightSceneObject={setHighlightedSceneObject}
                            onSelectScene={handleSelectScene}
                            onSelectSceneObject={handleSelectSceneObject}
                        />
                    }
                />
                <RoleChecker whitelist={[Role.Administrator]}>
                    <Accordion>
                        <ToggleHeader eventKey="0" onClick={() => setCollapsed((prev) => !prev)}>
                            <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />
                            <div>{t('others.advancedLocalityConfiguration', 'Advanced locality configuration')}</div>
                        </ToggleHeader>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <LocalityModulesForm
                                    errors={modulesErrors}
                                    locality={locality}
                                    modulesConfiguration={modulesConfiguration}
                                    organization={organization}
                                    selectableBoundaries={selectableBoundaries}
                                    selectableZones={selectableZones}
                                    onChange={setModulesConfiguration}
                                    onErrors={setModulesErrors}
                                    onHighlightSceneObject={setHighlightedSceneObject}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </RoleChecker>
            </Box>
        </>
    )
}

export default DescribeSceneTab
