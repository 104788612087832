import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { DateTime, Interval } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { Nav, Navbar as NavbarComponent, NavDropdown, Spinner } from 'react-bootstrap'
import { Switch as DarkModeToggle } from 'react-darkreader'
import { useTranslation } from 'react-i18next'
import { QueryStatus } from 'react-query'
import { Link, matchPath, NavLink, Route, Switch, useLocation } from 'react-router-dom'

import { OrganizationFeatures, OrganizationResponse, ProfileResponse, Role } from '@api/models'

import {
    ACCEPT_INVITATION_PATH,
    BOUNDARY_IDS_PARAM,
    CONVERSION_DASHBOARD_CUSTOM_INTERVAL_PATH,
    CONVERSION_DASHBOARD_EMPTY_PATH,
    CONVERSION_DASHBOARD_PATH,
    CUSTOM_INTERVAL_TYPE,
    DEVICE_MANAGEMENT_PATH,
    EMOTIONS_DETAILS_CUSTOM_INTERVAL_PATH,
    EMOTIONS_DETAILS_EMPTY_PATH,
    EMOTIONS_DETAILS_PATH,
    FOOTFALL_DETAILS_CUSTOM_INTERVAL_PATH,
    FOOTFALL_DETAILS_EMPTY_PATH,
    FOOTFALL_DETAILS_PATH,
    generateEditOrganizationPath,
    generateHeatmapDetailPath,
    generateNoLocalityNoFPHeatmapPath,
    generateOccupancyDetailsPath,
    generateStatisticsSummaryPath,
    HEATMAP_DETAIL_EMPTY_PATH,
    HEATMAP_DETAIL_PATH,
    HEATMAP_DETAIL_PATH_NO_LOCALITY_NO_FP,
    INSTANT_LOGIN_PATH,
    INTERVAL_END_PARAM,
    INTERVAL_START_PARAM,
    INTERVAL_TYPE_PARAM,
    LOGIN_PATH,
    OCCUPANCY_DETAILS_CUSTOM_INTERVAL_PATH,
    OCCUPANCY_DETAILS_PATH,
    ORGANIZATION_LIST_PATH,
    OrganizationEditPageTabs,
    QUEUE_DETAILS_CUSTOM_INTERVAL_PATH,
    QUEUE_DETAILS_EMPTY_PATH,
    QUEUE_DETAILS_PATH,
    REPORT_LIST_PATH,
    REQUEST_PASSWORD_RESET_PATH,
    SETTINGS_PATH,
    STATISTICS_SUMMARY_EMPTY_PATH,
    STATISTICS_SUMMARY_PATH,
    STAY_SAFE_DETAILS_EMPTY_PATH,
    STAY_SAFE_DETAILS_PATH,
    VIVIDI_APP,
    ZONE_IDS_PARAM,
} from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'

import FeatureChecker from '@components/FeatureChecker'
import KnowledgeBaseLink from '@components/KnowledgeBaseLink'
import RoleChecker from '@components/RoleChecker'
import { DarkModeContext } from '@components/darkmode/DarkModeProvider'

import logo from '@images/logo/logo.svg'

import { Params } from '../../pages/app/ConversionDetailsPage' // eslint-disable-line replace-relative-imports/replace

import styles from './Navbar.module.scss'

interface Props {
    className?: string
    profile?: ProfileResponse
    profileLoadingState: QueryStatus
    organizationFeatures?: OrganizationFeatures
    organization?: OrganizationResponse
    isAuthenticated: boolean
    onLogOut: () => void
}

const UserDropdown = React.memo((props: Props) => {
    const { darkMode, toggleDarkMode } = useContext(DarkModeContext)

    const { t } = useTranslation()
    const addBackLink = useAddBackLink()

    let humanReadableUserRole = ''

    if (props.profile) {
        switch (props.profile!.role.toLocaleLowerCase()) {
            case 'organization_owner':
                humanReadableUserRole = t('role.owner', 'Owner')
                break
            case 'organization_administrator':
                humanReadableUserRole = t('role.orgAdmin', 'Admin')
                break
            case 'administrator':
                humanReadableUserRole = t('role.administrator', 'Administrator')
                break
            case 'user':
                humanReadableUserRole = t('role.user', 'User')
                break
            default:
                humanReadableUserRole = t('role.administrator', 'Administrator')
        }
    }

    if (!props.isAuthenticated) {
        return null
    }

    if (props.profile !== undefined) {
        return (
            <>
                <RoleChecker whitelist={[Role.Administrator]}>
                    <Nav.Item className={styles.navItemContainer}>
                        <KnowledgeBaseLink />
                    </Nav.Item>
                    <Nav.Item className={styles.navItemContainer}>
                        <DarkModeToggle checked={darkMode} styling="github" onChange={toggleDarkMode} />
                    </Nav.Item>
                </RoleChecker>
                <NavDropdown
                    className={styles.dropdownToggle}
                    id="navbar-user-dropdown"
                    renderMenuOnMount={true}
                    title={
                        <>
                            <FontAwesomeIcon className={styles.avatar} icon={faUser} />
                            <div className={styles.email}>{props.profile!.email}</div>
                            <div className={styles.role}>
                                {props.organization?.name ?? 'Vividi'}&nbsp;
                                {humanReadableUserRole}
                            </div>
                            <FontAwesomeIcon className={styles.caret} icon={faCaretDown} />
                        </>
                    }
                >
                    <NavDropdown.Item as={Link} className={styles.userDropdownItem} to={addBackLink(SETTINGS_PATH)}>
                        <FontAwesomeIcon icon={faCog} /> {t('button.settings', 'Settings')}
                    </NavDropdown.Item>
                    <NavDropdown.Item className={styles.userDropdownItem} onClick={props.onLogOut}>
                        <FontAwesomeIcon icon={faSignOutAlt} /> {t('button.logOut', 'Log out')}
                    </NavDropdown.Item>
                </NavDropdown>
            </>
        )
    }

    return (
        <Nav.Item>
            {(props.profileLoadingState === 'idle' || props.profileLoadingState === 'loading') && (
                <Spinner animation="border" />
            )}
        </Nav.Item>
    )
})

const DynamicDropdown: React.FC<{
    id: string
    title: string
    items: Array<{ path: string; title: string; isAllowed: boolean; eventName: GoogleEventName }>
}> = (props) => {
    const allowedItems = props.items.filter((it) => it.isAllowed)

    if (allowedItems.length === 0) {
        return null
    }

    if (allowedItems.length === 1) {
        return (
            <Nav.Item>
                <Nav.Link
                    as={NavLink}
                    to={allowedItems[0].path}
                    onClick={() => trackEvent(GoogleEventCategory.NAVIGATION, allowedItems[0].eventName)}
                >
                    {allowedItems[0].title}
                </Nav.Link>
            </Nav.Item>
        )
    }

    return (
        <NavDropdown id={props.id} renderMenuOnMount={true} title={props.title}>
            {allowedItems.map((it) => (
                <NavDropdown.Item
                    key={it.path}
                    as={NavLink}
                    to={it.path}
                    onClick={() => trackEvent(GoogleEventCategory.NAVIGATION, it.eventName)}
                >
                    {it.title}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    )
}

export enum FrontendModules {
    CONVERSION = 'Conversion',
    FOOTFALL = 'Footfall',
    OCCUPANCY = 'Occupancy',
    QUEUE = 'Queue',
    REALTIME = 'Real-time',
    EMOTIONS = 'Emotions',
    STATSSUMMARY = 'StatsSummary',
    HEATMAPS = 'Heatmaps',
}

const generateNavbarDetailPagePath = (
    basePath: string,
    localityId: string,
    intervalStart: string,
    intervalType: IntervalType,
    intervalEnd?: string,
    disableCustomInterval?: boolean
) => {
    let path = basePath
    path = path.replace(':localityId?', localityId)
    path = path.replace(':localityId', localityId)
    path = path.replace(`${INTERVAL_START_PARAM}?/`, intervalStart)
    path = path.replace(BOUNDARY_IDS_PARAM, '')
    path = path.replace(ZONE_IDS_PARAM, '')

    if (disableCustomInterval) {
        path = path.replace(`${INTERVAL_TYPE_PARAM}`, `${IntervalType.WEEK}/`)

        return path
    }

    if (intervalType === IntervalType.CUSTOM) {
        path = path.replace(INTERVAL_START_PARAM, intervalStart)

        if (intervalEnd) {
            path = path.replace(INTERVAL_END_PARAM, intervalEnd)
        }

        path = path.replace(CUSTOM_INTERVAL_TYPE, IntervalType.CUSTOM)

        return path
    }

    path = path.replace(INTERVAL_TYPE_PARAM, `${intervalType}/`)

    return path
}

const generateModulePath =
    (localize: (datetime: DateTime) => DateTime) => (moduleName: FrontendModules, params: Partial<Params>) => {
        const { localityId, intervalType, intervalStart, intervalEnd } = params

        if (moduleName === FrontendModules.STATSSUMMARY) {
            const interval =
                intervalStart && intervalEnd && localize
                    ? Interval.fromDateTimes(
                          localize(DateTime.fromMillis(Number(intervalStart))),
                          localize(DateTime.fromMillis(Number(intervalEnd)))
                      )
                    : undefined

            return interval ? generateStatisticsSummaryPath(true, [], interval) : STATISTICS_SUMMARY_EMPTY_PATH
        }

        if (moduleName === FrontendModules.FOOTFALL) {
            return localityId && intervalStart && intervalType
                ? generateNavbarDetailPagePath(
                      intervalType === IntervalType.CUSTOM
                          ? FOOTFALL_DETAILS_CUSTOM_INTERVAL_PATH
                          : FOOTFALL_DETAILS_PATH,
                      localityId,
                      intervalStart,
                      intervalType,
                      intervalEnd
                  )
                : FOOTFALL_DETAILS_EMPTY_PATH
        }

        if (moduleName === FrontendModules.CONVERSION) {
            return localityId && intervalStart && intervalType
                ? generateNavbarDetailPagePath(
                      intervalType === IntervalType.CUSTOM
                          ? CONVERSION_DASHBOARD_CUSTOM_INTERVAL_PATH
                          : CONVERSION_DASHBOARD_PATH,
                      localityId,
                      intervalStart,
                      intervalType,
                      intervalEnd
                  )
                : CONVERSION_DASHBOARD_EMPTY_PATH
        }

        if (moduleName === FrontendModules.QUEUE) {
            return localityId && intervalStart && intervalType
                ? generateNavbarDetailPagePath(
                      intervalType === IntervalType.CUSTOM ? QUEUE_DETAILS_CUSTOM_INTERVAL_PATH : QUEUE_DETAILS_PATH,
                      localityId,
                      intervalStart,
                      intervalType,
                      intervalEnd
                  )
                : QUEUE_DETAILS_EMPTY_PATH
        }

        if (moduleName === FrontendModules.OCCUPANCY) {
            return localityId && intervalStart && intervalType
                ? generateNavbarDetailPagePath(
                      intervalType === IntervalType.CUSTOM
                          ? OCCUPANCY_DETAILS_CUSTOM_INTERVAL_PATH
                          : OCCUPANCY_DETAILS_PATH,
                      localityId,
                      intervalStart,
                      intervalType,
                      intervalEnd
                  )
                : generateOccupancyDetailsPath()
        }

        if (moduleName === FrontendModules.EMOTIONS) {
            return localityId && intervalStart && intervalType
                ? generateNavbarDetailPagePath(
                      intervalType === IntervalType.CUSTOM
                          ? EMOTIONS_DETAILS_CUSTOM_INTERVAL_PATH
                          : EMOTIONS_DETAILS_PATH,
                      localityId,
                      intervalStart,
                      intervalType,
                      intervalEnd
                  )
                : EMOTIONS_DETAILS_EMPTY_PATH
        }

        if (moduleName === FrontendModules.HEATMAPS) {
            if (localityId && intervalStart) {
                return generateHeatmapDetailPath(Number(localityId), DateTime.fromMillis(Number(intervalStart)))
            }

            if (!localityId && intervalStart && intervalEnd) {
                return generateNoLocalityNoFPHeatmapPath(
                    DateTime.fromMillis(Number(intervalStart)),
                    DateTime.fromMillis(Number(intervalEnd))
                )
            }

            return HEATMAP_DETAIL_EMPTY_PATH
        }

        if (moduleName === FrontendModules.REALTIME) {
            return STAY_SAFE_DETAILS_EMPTY_PATH
        }
    }

const VividiAppNav = (props: Props) => {
    const { t } = useTranslation()

    const location = useLocation()
    const localize = useLocalizeDateTime()
    const generatePath = generateModulePath(localize)

    const params: Partial<Params> =
        [
            FOOTFALL_DETAILS_CUSTOM_INTERVAL_PATH,
            CONVERSION_DASHBOARD_CUSTOM_INTERVAL_PATH,
            QUEUE_DETAILS_CUSTOM_INTERVAL_PATH,
            OCCUPANCY_DETAILS_CUSTOM_INTERVAL_PATH,
            EMOTIONS_DETAILS_CUSTOM_INTERVAL_PATH,
            HEATMAP_DETAIL_PATH_NO_LOCALITY_NO_FP,
            HEATMAP_DETAIL_PATH,
            EMOTIONS_DETAILS_PATH,
            CONVERSION_DASHBOARD_PATH,
            QUEUE_DETAILS_PATH,
            FOOTFALL_DETAILS_PATH,
            STAY_SAFE_DETAILS_PATH,
            OCCUPANCY_DETAILS_PATH,
            STATISTICS_SUMMARY_PATH,
        ]
            .map((path) => matchPath(location.pathname, { path }))
            .find((match) => match !== null)?.params ?? {}

    const isAdmin = props.profile?.role === Role.Administrator
    const canViewFootfall = isAdmin || Boolean(props.organizationFeatures?.footfall)
    const canViewQueue = isAdmin || Boolean(props.organizationFeatures?.queueMonitoring)
    const canViewConversion = isAdmin || Boolean(props.organizationFeatures?.conversions)
    const canViewZoneOccupancy = isAdmin || Boolean(props.organizationFeatures?.zoneOccupancy)
    const canViewStaysafe = isAdmin || Boolean(props.organizationFeatures?.realtimeOccupancy)
    const canVieWEMotions = isAdmin || Boolean(props.organizationFeatures?.emotions)
    const canViewHeatmaps = isAdmin || Boolean(props.organizationFeatures?.floorplans)

    return (
        <>
            <Nav.Item>
                <NavLink
                    className="nav-link"
                    to={generatePath(FrontendModules.STATSSUMMARY, params) ?? '/'}
                    onClick={() => trackEvent(GoogleEventCategory.NAVIGATION, GoogleEventName.MENU_CLICK_HOME)}
                >
                    {t('navbar.home', 'Home')}
                </NavLink>
            </Nav.Item>
            <DynamicDropdown
                id="navbar-statistics-dropdown"
                items={[
                    {
                        path: generatePath(FrontendModules.FOOTFALL, params) ?? '/',
                        title: t('button.footfall', 'Footfall'),
                        isAllowed: canViewFootfall,
                        eventName: GoogleEventName.MENU_CLICK_FOOTFALL,
                    },
                    {
                        path: generatePath(FrontendModules.REALTIME, params) ?? '/',
                        title: t('realTime.title', 'Real-time'),
                        isAllowed: canViewStaysafe,
                        eventName: GoogleEventName.MENU_CLICK_REALTIME,
                    },
                    {
                        path: generatePath(FrontendModules.CONVERSION, params) ?? '/',
                        title: t('navbar.conversions', 'Conversions'),
                        isAllowed: canViewConversion,
                        eventName: GoogleEventName.MENU_CLICK_CONVERSIONS,
                    },
                    {
                        path: generatePath(FrontendModules.QUEUE, params) ?? '/',
                        title: t('navbar.queues', 'Queues'),
                        isAllowed: canViewQueue,
                        eventName: GoogleEventName.MENU_CLICK_QUEUES,
                    },
                    {
                        path: generatePath(FrontendModules.HEATMAPS, params) ?? '/',
                        title: t('heatmaps.title', 'Heatmaps'),
                        isAllowed: canViewHeatmaps,
                        eventName: GoogleEventName.MENU_CLICK_HEATMAPS,
                    },
                    {
                        path: generatePath(FrontendModules.OCCUPANCY, params) ?? '/',
                        title: t('button.occupancy', 'Occupancy'),
                        isAllowed: canViewZoneOccupancy,
                        eventName: GoogleEventName.MENU_CLICK_OCCUPANCY,
                    },
                    {
                        path: generatePath(FrontendModules.EMOTIONS, params) ?? '/',
                        title: t('button.emotions', 'Emotions'),
                        isAllowed: canVieWEMotions,
                        eventName: GoogleEventName.MENU_CLICK_EMOTIONS,
                    },
                ]}
                title={t('navbar.statistics', 'Statistics')}
            />
            <FeatureChecker features={['zoneOccupancy', 'footfall', 'realtimeOccupancy']}>
                <Nav.Item>
                    <Nav.Link
                        as={NavLink}
                        to={REPORT_LIST_PATH}
                        onClick={() => trackEvent(GoogleEventCategory.NAVIGATION, GoogleEventName.MENU_CLICK_REPORTS)}
                    >
                        {t('navbar.reports', 'Reports')}
                    </Nav.Link>
                </Nav.Item>
            </FeatureChecker>
            <RoleChecker whitelist={[Role.Administrator]}>
                <Nav.Item>
                    <NavLink
                        className="nav-link"
                        to={ORGANIZATION_LIST_PATH}
                        onClick={() =>
                            trackEvent(GoogleEventCategory.NAVIGATION, GoogleEventName.MENU_CLICK_ORGANIZATIONS)
                        }
                    >
                        {t('navbar.organizations', 'Organizations')}
                    </NavLink>
                </Nav.Item>
            </RoleChecker>
            <RoleChecker whitelist={[Role.Administrator]}>
                <Nav.Item>
                    <NavLink
                        className="nav-link"
                        to={DEVICE_MANAGEMENT_PATH}
                        onClick={() => trackEvent(GoogleEventCategory.NAVIGATION, GoogleEventName.MENU_CLICK_DEVICES)}
                    >
                        {t('table.devices', 'Devices')}
                    </NavLink>
                </Nav.Item>
            </RoleChecker>
            {props.profileLoadingState === 'success' &&
                props.profile !== undefined &&
                props.profile.organizationId !== undefined && (
                    <RoleChecker whitelist={[Role.OrganizationAdministrator, Role.OrganizationOwner]}>
                        <Nav.Item>
                            <NavLink
                                className="nav-link"
                                to={generateEditOrganizationPath(
                                    props.profile.organizationId,
                                    OrganizationEditPageTabs.localities
                                )}
                            >
                                {t('navbar.myOrganization', 'My organization')}
                            </NavLink>
                        </Nav.Item>
                    </RoleChecker>
                )}
        </>
    )
}

const VividiLink = () => (
    <Link to={STATISTICS_SUMMARY_EMPTY_PATH}>
        <img
            alt=""
            className={styles.logo}
            src={logo}
            onClick={() => trackEvent(GoogleEventCategory.NAVIGATION, GoogleEventName.MENU_CLICK_LOGO)}
        />
    </Link>
)

const NavbarView: React.FC<Props> = (props) => {
    const [expanded, setExpanded] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setExpanded(false)
    }, [location])

    return (
        <NavbarComponent
            className={classNames(styles.mainNavbar, props.className)}
            expand="lg"
            expanded={expanded}
            onToggle={setExpanded}
        >
            <NavbarComponent.Brand>
                <Switch>
                    <Route component={VividiLink} path={LOGIN_PATH} />
                    <Route component={VividiLink} path={VIVIDI_APP} />
                </Switch>
            </NavbarComponent.Brand>
            <NavbarComponent.Toggle aria-controls="basic-navbar-nav" />
            <NavbarComponent.Collapse id="basic-navbar-nav">
                <Switch>
                    <Route children={() => null} path={LOGIN_PATH} />
                    <Route children={() => null} path={INSTANT_LOGIN_PATH} />
                    <Route children={() => null} path={REQUEST_PASSWORD_RESET_PATH} />
                    <Route children={() => null} path={ACCEPT_INVITATION_PATH} />
                    <Route
                        children={() => (
                            <>
                                <Nav>
                                    <VividiAppNav {...props} />
                                </Nav>
                                <Nav className="ml-auto">
                                    <UserDropdown {...props} />
                                </Nav>
                            </>
                        )}
                    />
                </Switch>
            </NavbarComponent.Collapse>
        </NavbarComponent>
    )
}

export default NavbarView
