import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mapValues, max, noop, range, round, uniqueId } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useMemo, useRef, useState } from 'react'
import { Col, Form, ProgressBar, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Area, CartesianGrid, YAxis, Tooltip, BarChart, Bar, XAxis } from 'recharts'

import {
    ConversionStatisticsResponse,
    SceneDescription,
    LocalityResponse,
    OutagesResponse,
    SceneListResponse,
} from '@api/models'

import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { IntervalType, TooltipFormatter } from '@helpers/types'
import { percentage } from '@helpers/utils'

import Box from '@elements/Box/Box'
import PlotButton from '@elements/Buttons/PlotButton'

import { ConfigurationHeatmapCarousel } from '@components/ConfigurationHeatmapCarousel'
import {
    ConversionData,
    prepareConversionData,
    prepareHourlyBouncedData,
    prepareSummedConversionData,
    prepareWeeklyBouncedData,
} from '@components/ConversionDashboard/conversionDetailsPreprocessor'
import ConversionStatisticBox from '@components/StatisticsSummary/ConversionStatisticBox'
import palette from '@components/palette.module.scss'
import Cursor from '@components/plots/Cursor'
import StatisticsSummaryConversionPlot from '@components/plots/StatisticsSummaryConversionPlot'
import TimeLineChart from '@components/plots/TimeLineChart'
import TooltipContent from '@components/plots/TooltipContent'
import TooltipLabel from '@components/plots/TooltipLabel'
import TooltipTable from '@components/plots/TooltipTable'

import styles from './ConversionDetails.module.scss'

interface ConversionChangeIndicatorProps {
    passerbyToVisitors: number
    bounceRate: number
    visitorsToSales: number
}

const ConversionChangeIndicators: React.FC<ConversionChangeIndicatorProps> = ({
    passerbyToVisitors,
    bounceRate,
    visitorsToSales,
}) => {
    const { t } = useTranslation()

    return (
        <div className={styles.conversionChangeIndicatorLayout}>
            <div>
                <div className={styles.conversionChangeIndicatorText}>
                    <span className={styles.changeText}>
                        {t('conversions.passerby', 'Passerby ')}
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                        {t('conversions.visitor', ' Visitor')}
                    </span>
                    <span className={styles.changePercentage}>{passerbyToVisitors} %</span>
                </div>
                <ProgressBar className={styles.conversionChangeIndicator} now={passerbyToVisitors} />
            </div>
            <div>
                <div className={styles.conversionChangeIndicatorText}>
                    <span className={styles.changeText}>{t('heading.bounceRate')}</span>
                    <span className={styles.bouncedPercentage}>{bounceRate} %</span>
                </div>
                <ProgressBar className={styles.conversionChangeIndicator} now={bounceRate} variant="danger" />
            </div>
            <div>
                <div className={styles.conversionChangeIndicatorText}>
                    <span className={styles.changeText}>
                        {t('conversions.browsingVisitor', 'Browsing visitor ')}
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                        {t('conversions.posInteraction', 'POS interaction')}
                    </span>
                    <span className={styles.changePercentage}>{visitorsToSales} %</span>
                </div>
                <ProgressBar className={styles.conversionChangeIndicator} now={visitorsToSales} />
            </div>
        </div>
    )
}

interface ConversionPlotProps {
    conversionData: ConversionData
    outages: OutagesResponse
    now: DateTime
    intervalType: IntervalType
    interval: Interval
}

const ConversionPlot: React.FC<ConversionPlotProps> = ({ conversionData, now, outages, intervalType, interval }) => {
    const { t } = useTranslation()

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const ids = useMemo(() => range(4).map(() => uniqueId()), [])
    const [displayedGraphs, setDisplayedGraphs] = useState({
        passersby: true,
        visitors: true,
        sales: true,
        bounced: true,
    })

    const plotData = conversionData.map((it) => ({
        ...it,
        hasData: [it.passersby, it.visitors, it.sales, it.bounced].some((it) => it !== undefined),
        passersby: it.passersby ?? 0,
        visitors: it.visitors ?? 0,
        sales: it.sales ?? 0,
        bounced: it.bounced ?? 0,
        bouncedPercentage: round(percentage(it.bounced ?? 0, it.visitors ?? 0), 1),
    }))

    const maxData =
        max(
            conversionData.flatMap((it) => [
                displayedGraphs.passersby && it.passersby ? it.passersby : 0,
                displayedGraphs.visitors && it.visitors ? it.visitors : 0,
                displayedGraphs.sales && it.sales ? it.sales : 0,
                displayedGraphs.bounced && it.bounced ? it.bounced : 0,
            ])
        ) ?? 0

    const canvasContext = canvasRef.current?.getContext('2d')
    const axisWidth = canvasContext?.measureText((-maxData).toString()).width

    return (
        <>
            <div>
                <Form>
                    <Form.Group>
                        <span className={styles.plotControlsLabel}>
                            <strong>{t('others.show', 'Show:')}</strong>
                        </span>
                        <PlotButton
                            buttonText={t('conversions.passersby', 'Passersby')}
                            id={ids[0]}
                            isActive={displayedGraphs.passersby}
                            underlineColor={palette.mediumGrey}
                            onClick={() =>
                                setDisplayedGraphs((prev) => ({
                                    ...prev,
                                    passersby: !prev.passersby,
                                }))
                            }
                        />
                        <PlotButton
                            buttonText={t('conversions.visitors', 'Visitors')}
                            id={ids[1]}
                            isActive={displayedGraphs.visitors}
                            underlineColor={palette.vividiPrimaryLight}
                            onClick={() =>
                                setDisplayedGraphs((prev) => ({
                                    ...prev,
                                    visitors: !prev.visitors,
                                }))
                            }
                        />
                        <PlotButton
                            buttonText={t('statistics.posInteractions', 'POS interactions')}
                            id={ids[2]}
                            isActive={displayedGraphs.sales}
                            underlineColor={palette.vividiPrimary}
                            onClick={() =>
                                setDisplayedGraphs((prev) => ({
                                    ...prev,
                                    sales: !prev.sales,
                                }))
                            }
                        />
                        <PlotButton
                            buttonText={t('conversions.bounced', 'Bounced')}
                            id={ids[3]}
                            isActive={displayedGraphs.bounced}
                            underlineColor={palette.bouncedRed}
                            onClick={() =>
                                setDisplayedGraphs((prev) => ({
                                    ...prev,
                                    bounced: !prev.bounced,
                                }))
                            }
                        />
                    </Form.Group>
                </Form>
            </div>
            <TimeLineChart
                canvasRef={canvasRef}
                data={plotData}
                endDate={interval.end}
                intervalType={intervalType}
                now={now}
                outages={outages.outages}
                startDate={interval.end}
                onSelectInterval={noop}
            >
                <YAxis
                    allowDecimals={false}
                    domain={[0, 1.1 * maxData]}
                    tick={(tickProps) => {
                        return (
                            <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                                <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4} y={4}>
                                    {tickProps.payload.value}
                                </text>
                            </g>
                        )
                    }}
                    ticks={[0, Math.round(maxData / 2), maxData]}
                    width={Math.ceil(axisWidth ?? 30) + 6}
                />
                {displayedGraphs.passersby && (
                    <Area
                        dataKey="passersby"
                        fill={palette.mediumGrey}
                        fillOpacity={0.2}
                        stroke={palette.mediumGrey}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                {displayedGraphs.visitors && (
                    <Area
                        dataKey="visitors"
                        fill={palette.vividiPrimaryLight}
                        fillOpacity={0.2}
                        stroke={palette.vividiPrimaryLight}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                {displayedGraphs.sales && (
                    <Area
                        dataKey="sales"
                        fill={palette.vividiPrimary}
                        fillOpacity={0.2}
                        stroke={palette.vividiPrimary}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                {displayedGraphs.bounced && (
                    <Area
                        dataKey="bounced"
                        fill={palette.bouncedRed}
                        fillOpacity={0.2}
                        stroke={palette.bouncedRed}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                <CartesianGrid strokeOpacity={0.3} strokeWidth={1} vertical={false} />
                <Tooltip
                    content={
                        <TooltipContent
                            intervalResolver={(label) => plotData[Number(label)].interval}
                            labelFormatter={(label) => label}
                            now={now}
                            outages={[]}
                        >
                            {(label) => {
                                const record = plotData.find((it) => it.name === label)!

                                return (
                                    <TooltipTable>
                                        <TooltipTable.Item label={t('form.numberOfPassersby', 'Number of passersby')}>
                                            {record.passersby ?? 0}
                                        </TooltipTable.Item>
                                        <TooltipTable.Item label={t('form.numberOfVisitors', 'Number of visitors')}>
                                            <span>{record.visitors ?? 0}</span>
                                        </TooltipTable.Item>
                                        <TooltipTable.Item label={t('statistics.posInteractions', 'POS interactions')}>
                                            <span
                                                style={{
                                                    color: palette.vividiPrimary,
                                                }}
                                            >
                                                {record.sales ?? 0}
                                            </span>
                                        </TooltipTable.Item>
                                        <TooltipTable.Item label={t('form.bounceRate', 'Bounce Rate')}>
                                            <span
                                                style={{
                                                    color: palette.bouncedRed,
                                                }}
                                            >
                                                {record.bouncedPercentage ?? 0}%
                                            </span>
                                        </TooltipTable.Item>
                                    </TooltipTable>
                                )
                            }}
                        </TooltipContent>
                    }
                    cursor={<Cursor dataLength={plotData.length} />}
                    filterNull={false}
                    formatter={
                        ((value: number, name: string) => {
                            if (name === 'incoming') {
                                return [value, t('statistics.numberIncoming', 'Number of incoming')]
                            }

                            if (name === 'outgoing') {
                                return [value, t('statistics.numberOutgoing', 'Number of outgoing')]
                            }

                            return [value, name]
                        }) as TooltipFormatter
                    }
                    labelFormatter={(index: string | number) => {
                        const item = plotData[parseInt(index as string, 10)]

                        if (item === undefined) {
                            return
                        }

                        const interval = item.interval

                        return (
                            <TooltipLabel
                                endDate={interval.end}
                                interval={interval}
                                now={now}
                                startDate={interval.start}
                            />
                        )
                    }}
                />
            </TimeLineChart>
        </>
    )
}

interface BouncedBarChartProps {
    width: number
    height: number
    hourlyConversions: ConversionStatisticsResponse
    now: DateTime
}

const BouncedWeeklyBarChart: React.FC<BouncedBarChartProps> = ({ width, height, hourlyConversions, now }) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()

    const plotData = prepareWeeklyBouncedData(hourlyConversions, localize)

    const maxData = max(plotData.map((it) => it.bouncedPercentage)) ?? 0

    return (
        <BarChart data={plotData} height={height} width={width}>
            <XAxis
                dataKey="name"
                tick={(tickProps) => {
                    return (
                        <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                            <text className="small" fill={palette.darkgrey} textAnchor="end" x={+16} y={16}>
                                {tickProps.payload.value}
                            </text>
                        </g>
                    )
                }}
            />
            <YAxis
                tick={(tickProps) => {
                    return (
                        <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                            <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4}>
                                {tickProps.payload.value} %
                            </text>
                        </g>
                    )
                }}
                ticks={[0, Math.round(maxData / 2), maxData]}
            />
            <Tooltip
                content={
                    <TooltipContent
                        customLabel={t('heading.bounceRate', 'Bounce rate')}
                        labelFormatter={(day) => (
                            <span>
                                <strong>
                                    {t('tooltip.bouncedVisitorsOnLabel', 'Bounced visitors on {{label}}', {
                                        label: day,
                                    })}
                                </strong>
                            </span>
                        )}
                        now={now}
                        outages={[]}
                    />
                }
                formatter={
                    ((value: number, name: string) => {
                        return [`${value} %`, name]
                    }) as TooltipFormatter
                }
                label={t('conversions.bouncedVisitors', 'Bounced visitors ')}
            />
            <Bar dataKey="bouncedPercentage" fill={palette.vividiPrimary} />
        </BarChart>
    )
}

const BouncedHourlyBarChart: React.FC<BouncedBarChartProps> = ({ width, height, hourlyConversions, now }) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()

    const plotData = prepareHourlyBouncedData(hourlyConversions, localize)

    const maxData = max(plotData.map((it) => it.bouncedPercentage)) ?? 0

    return (
        <BarChart data={plotData} height={height} width={width}>
            <XAxis
                dataKey="name"
                tick={(tickProps) => {
                    return (
                        <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                            <text className="small" fill={palette.darkgrey} textAnchor="end" x={+16} y={16}>
                                {tickProps.payload.value}
                            </text>
                        </g>
                    )
                }}
            />
            <YAxis
                tick={(tickProps) => {
                    return (
                        <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                            <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4}>
                                {tickProps.payload.value} %
                            </text>
                        </g>
                    )
                }}
                ticks={[0, Math.round(maxData / 2), maxData]}
            />
            <Tooltip
                content={
                    <TooltipContent
                        customLabel={t('heading.bounceRate', 'Bounce rate')}
                        formatter={
                            ((value: number, name: string) => {
                                return [`${value} %`, name]
                            }) as TooltipFormatter
                        }
                        labelFormatter={(label) => (
                            <span>
                                <strong>
                                    {t('tooltip.bouncedVisitorsBetweenDates', 'Bounced visitors between {{dates}}', {
                                        dates: `${label} - ${
                                            plotData[plotData.findIndex((it) => it.name === label) + 1]?.name ??
                                            `${Number(label.split(':')[0]) + 1}:00`
                                        }`,
                                    })}
                                </strong>
                            </span>
                        )}
                        now={now}
                        outages={[]}
                    />
                }
            />
            <Bar dataKey="bouncedPercentage" fill={palette.vividiPrimary} />
        </BarChart>
    )
}

interface Props {
    conversionStatistics: ConversionStatisticsResponse
    hourlyConversions: ConversionStatisticsResponse
    scenes: SceneListResponse
    outages: OutagesResponse
    now: DateTime
    interval: Interval
    intervalType: IntervalType
    locality: LocalityResponse
    sceneConfiguration: { [deviceId: number]: SceneDescription }
}

const ConversionDetailsContent: React.FC<Props> = ({
    conversionStatistics,
    hourlyConversions,
    outages,
    now,
    interval,
    scenes,
    intervalType,
    locality,
    sceneConfiguration,
}) => {
    const { t } = useTranslation()

    const scenesInLocality = scenes.scenes.filter((s) => s.localityIds.includes(locality.id))

    const conversionData = useMemo(
        () => prepareConversionData(conversionStatistics, now, outages),
        [conversionStatistics, outages, now]
    )

    const summedConversionsData = useMemo(
        () => prepareSummedConversionData(conversionStatistics),
        [conversionStatistics]
    )

    const conversionPlotData = useMemo(
        () => [
            {
                name: t('conversions.totalPassersby', 'Total passersby'),
                value: summedConversionsData.totalPassersBy,
                description: t(
                    'conversions.totalPassersbyDescription',
                    'the total number of people who pass the store in the selected localities'
                ),
            },
            {
                name: t('statistics.totalVisitors', 'Total visitors'),
                value: summedConversionsData.totalVisitors,
                description: t(
                    'conversions.totalVisitorsDescription',
                    'the total number of people who enter the store in the selected localities'
                ),
            },
            {
                name: t('statistics.totalBrowsingVisitors', 'Total browsing visitors'),
                value: summedConversionsData.totalBrowsingVisitors,
                description: t(
                    'conversions.totalBrowsingVisitorsDescription',
                    'the total number of people who enter and browse the store (do not leave immediately) in the selected localities'
                ),
            },
            {
                name: t('statistics.posInteractions', 'POS interactions'),
                value: summedConversionsData.totalSales,
                description: t('statistics.totalSalesDescription', 'total number of point-of-sales interactions'),
            },
        ],
        [summedConversionsData]
    )

    return (
        <>
            <Row>
                <Col>
                    <Box>
                        <Box.Title text={t('heading.conversionFunnel', 'Conversion funnel')} />
                        <ConversionStatisticBox
                            comparisonInterval={undefined}
                            data={conversionPlotData}
                            interval={interval}
                            localities={[locality]}
                        />
                        <div className={styles.conversionPlotContainer}>
                            <AutoSizer>
                                {({ width, height }) => (
                                    <StatisticsSummaryConversionPlot
                                        className={styles.conversionPlot}
                                        data={[
                                            ...conversionPlotData,
                                            conversionPlotData[conversionPlotData.length - 1],
                                        ]}
                                        height={height}
                                        width={width}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                        <Box.Title text={t('heading.conversionRates', 'Conversion rates')} />
                        <ConversionChangeIndicators
                            bounceRate={summedConversionsData.bounceRate}
                            passerbyToVisitors={summedConversionsData.passerbyToVisitors}
                            visitorsToSales={summedConversionsData.visitorsToSales}
                        />
                    </Box>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Box className={styles.conversionPlot}>
                        <Box.Title text={t('heading.conversionFlowInTime', 'Conversion flow in time')} />
                        <ConversionPlot
                            conversionData={conversionData}
                            interval={interval}
                            intervalType={intervalType}
                            now={now}
                            outages={outages}
                        />
                    </Box>
                </Col>
            </Row>

            <Row>
                <Col lg={5} md={12}>
                    <Box>
                        <Box.Title text={t('heading.bouncedDuringWeek', 'Bounced visitors during a week')} />
                        <div className={styles.conversionPlotContainer}>
                            <AutoSizer>
                                {({ width, height }) => (
                                    <BouncedWeeklyBarChart
                                        height={height}
                                        hourlyConversions={hourlyConversions}
                                        now={now}
                                        width={width}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                    </Box>
                </Col>
                <Col className="pt-4 pt-lg-0" lg={7} md={12}>
                    <Box>
                        <Box.Title text={t('heading.bouncedDuringDay', 'Bounced visitors during a day')} />
                        <div className={styles.conversionPlotContainer}>
                            <AutoSizer>
                                {({ width, height }) => (
                                    <BouncedHourlyBarChart
                                        height={height}
                                        hourlyConversions={hourlyConversions}
                                        now={now}
                                        width={width}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box className={styles.heatmapCard}>
                        <Box.Title
                            text={`${locality.name} ${t(
                                'heading.conversionBoundariesZones',
                                ' conversion boundaries and zones'
                            )}`}
                        />
                        <ConfigurationHeatmapCarousel
                            boundaries={mapValues(sceneConfiguration, (config) =>
                                config.visitBoundaries.filter(
                                    (b) =>
                                        locality.conversionsConfiguration?.passerByBoundaries?.includes(b.id) ||
                                        locality.conversionsConfiguration?.visitorBoundaries?.includes(b.id)
                                )
                            )}
                            drawBoundaries={true}
                            drawZones={true}
                            locality={locality}
                            scenes={scenesInLocality}
                            zones={mapValues(sceneConfiguration, (config) =>
                                config.detectionZones.filter((z) =>
                                    locality.queuesConfiguration?.salesZones?.includes(z.id)
                                )
                            )}
                        />
                    </Box>
                </Col>
            </Row>
        </>
    )
}

export default ConversionDetailsContent
