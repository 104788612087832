import { faMapPin, faSync, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import produce from 'immer'
import { Dictionary, flatMap, head, isEmpty, isEqual, last, mapKeys, noop, uniq } from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Token, TokenProps } from 'react-bootstrap-typeahead'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useMutation, useQuery, UseQueryResult } from 'react-query'
import { Link, NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import {
    LocalityModel,
    LocalityNameListResponse,
    LocalityNameModel,
    LocalityOpeningHoursListResponse,
    LocalityResponse,
    OpeningHours,
    OpeningHoursInterval,
    OrganizationResponse,
    ProfileResponse,
    SceneDescription,
    SceneResponse,
    Time,
    Location,
    UserResponse,
    Role,
} from '@api'

import { backgroundJobsApi, localityApi } from '@services'

import {
    generateOrganizationEditLocalityFloorplanMappingPath,
    generateOrganizationEditLocalityFloorplanPath,
    generateOrganizationEditUserPath,
    generateOrganizationsEditLocalitiesOpeningHoursPath,
    generateOrganizationsEditLocalitiesPath,
    ORGANIZATION_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH,
    ORGANIZATION_LOCALITY_EDIT_OPENING_HOURS_PATH,
    generateOrganizationScenesPath,
} from '@helpers/VividiURLs'
import { mergeQueryResults } from '@helpers/api'
import { stableSort } from '@helpers/orderFunctions'
import useProfile from '@helpers/profile'
import { displayTranslatedDayInWeek } from '@helpers/translateUtils'
import { defaultErrorEntry, ErrorEntry } from '@helpers/types'

import { useUploadFloorplan } from '@hooks/useUploadFloorplan'

import IconButton from '@elements/Buttons/IconButton'
import ToggleButton from '@elements/Buttons/ToggleButton'

import ControlledModal from '@components/ControlledModal'
import DescribeSceneTabModal from '@components/DescribeSceneTab/DescribeSceneTabModal'
import TextInput from '@components/GenericInputs/TextInput'
import { LayoutHeading } from '@components/Layouts/LayoutHeading'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import LocalityFloorplanModal from '@components/LocalityFloorplanModal'
import LocalityOpeningHoursModal from '@components/LocalityOpeningHoursModal'
import MultiSelectBox from '@components/MultiSelectBox'
import { DaysInWeekShortened } from '@components/OpeningHoursPicker/OpeningHoursPicker'
import PlacePicker from '@components/PlacePicker'
import PopConfirm from '@components/PopConfirm/PopConfirm'
import RoleChecker from '@components/RoleChecker'
import TooltipIcon from '@components/TooltipIcon'
import { useNotify } from '@components/notifications/NotificationsContext'

import { ReactComponent as FloorplanIcon } from '@images/icons/floorplan_icon.svg'

import { LocalitiesEditData } from './LocalitiesTab'
import styles from './LocalitiesTabForm.module.scss'

export type LocalitiesEditFormErrors = {
    name: ErrorEntry
}

export const isExistingLocality = (
    locality: LocalityModel | LocalityNameModel | undefined
): locality is LocalityNameModel => (locality as LocalityNameModel)?.id !== undefined

const aggregateWeekdaysByOpeningHours = (openingHours: OpeningHours) => {
    const weekdays: Array<keyof OpeningHours> = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

    const groupedWeekdays = weekdays.reduce((acc, weekday) => {
        if (last(acc) === undefined) {
            return [[weekday]]
        }

        const currentOpeningHours = openingHours[weekday]
        const accumulatorOpeningHours = openingHours[last(acc)![0] as keyof OpeningHours]

        if (isEqual(currentOpeningHours, accumulatorOpeningHours)) {
            return produce(acc, (draft) => {
                last(draft)!.push(weekday)
            })
        }

        return [...acc, [weekday]]
    }, [] as Array<Array<keyof OpeningHours>>)

    const aggregateWeekdays: { [days: string]: Array<OpeningHoursInterval> } = Object.fromEntries(
        groupedWeekdays
            .map((weekdays) => {
                const hours = openingHours[last(weekdays)!]

                const days = weekdays.length > 1 ? `${head(weekdays)}-${last(weekdays)}` : weekdays

                return [days, hours]
            })
            .filter(([, hours]) => !isEmpty(hours))
    )

    return aggregateWeekdays
}

const formatOpeningHours = (openingTime: Time, closingTime: Time) =>
    `${openingTime.hour}:${
        openingTime.minute > 0
            ? openingTime.minute.toString().length === 1
                ? `0${openingTime.minute}`
                : openingTime.minute
            : '00'
    } - ${closingTime.hour}:${
        closingTime.minute > 0
            ? closingTime.minute.toString().length === 1
                ? `0${closingTime.minute}`
                : closingTime.minute
            : '00'
    }${' '}`

const NameRow: React.FC<{
    name: string
    errors: LocalitiesEditFormErrors
    onUpdate: (value: string) => void
    isNewLocality: boolean
}> = ({ name, errors, onUpdate, isNewLocality }) => {
    const { t } = useTranslation()

    const { isTouched, isInvalid } = errors.name

    return (
        <Form.Group as={Row} className={styles.row}>
            <Col md={2}>{t('form.name', 'Name')}</Col>
            <Col md={10}>
                <TextInput
                    autofocus={isNewLocality}
                    isInvalid={isTouched && isInvalid}
                    value={name ?? ''}
                    onChange={onUpdate}
                />
            </Col>
        </Form.Group>
    )
}

const LabelRow = ({
    labels,
    onUpdate,
    labelChoices,
}: {
    labels: Array<string>
    onUpdate: (value: Array<string>) => void
    labelChoices: {
        [k: string]: string
    }
}) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row} className={styles.row}>
            <Form.Label className={styles.labelWithIcon} column={true} md={2} sm={12}>
                {t('form.labels', 'Labels')}
                <TooltipIcon>
                    {t(
                        'tooltip.labelMaxLength',
                        'You can insert multiple labels. Maximum length of the label is 20 characters.'
                    )}
                </TooltipIcon>
            </Form.Label>
            <Col md={10}>
                <MultiSelectBox choices={labelChoices} selection={labels ?? []} allowNew onSelect={onUpdate} />
            </Col>
        </Form.Group>
    )
}

const FloorplanRow = ({ localityId, organization }: { localityId: number; organization: OrganizationResponse }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()

    const { open, getRootProps, getInputProps, isUploading, uploadedFileName } = useUploadFloorplan({
        organizationId: organization.id,
        localityId,
    })

    const handleOpenModal = (floorplanId: string) =>
        history.push(generateOrganizationEditLocalityFloorplanPath(organization.id, localityId, Number(floorplanId)))

    const handleCloseModal = () => history.push(generateOrganizationsEditLocalitiesPath(organization.id, localityId))

    const localityCall = useQuery(
        localityApi.getLocality.query({
            localityId,
        })
    )

    return (
        <LegacyLoadingWrapper
            placeholder={
                <Form.Group as={Row} className={styles.row}>
                    <Col className={styles.label} md={2} sm={12}>
                        {t('tab.floorplan', 'Floor plan')}
                    </Col>
                    <Col md={6}>
                        <span className="text-muted">{t('others.Loading', 'Loading')}</span>
                    </Col>
                    <Col md={4}>
                        <Button className={classNames(styles.floorplansButton, styles.hidden)}>placeholder</Button>
                    </Col>
                </Form.Group>
            }
            request={localityCall}
        >
            {(locality) => {
                const containsFloorplans = Object.keys(locality.floorplans).length > 0

                return (
                    <Form.Group as={Row} className={styles.row}>
                        <Col md={2}>{t('tab.floorplan', 'Floor plan')}</Col>
                        {isUploading ? (
                            <>
                                <Col className={styles.loadingSpinnerContainer} md={10}>
                                    <Spinner animation="border" />
                                    <span className="text-muted">
                                        {uploadedFileName} {t('others.beingUploaded', 'is being uploaded')}
                                    </span>
                                </Col>
                            </>
                        ) : (
                            <>
                                {locality.floorplans && !isEmpty(locality.floorplans) && (
                                    <>
                                        <Col md={5}>
                                            {Object.entries(locality.floorplans).map(([key, name]) => (
                                                <LocalityFloorplanModal
                                                    key={key}
                                                    floorplanName={name}
                                                    isFloorplanModalOpen={
                                                        pathname ===
                                                        generateOrganizationEditLocalityFloorplanPath(
                                                            organization.id,
                                                            localityId,
                                                            Number(key)
                                                        )
                                                    }
                                                    locality={locality}
                                                    organization={organization}
                                                    onFloorplanModalClose={handleCloseModal}
                                                    onFloorplanModalOpen={() => handleOpenModal(key)}
                                                />
                                            ))}
                                        </Col>
                                        <Col
                                            className={styles.actionsColumn}
                                            md={containsFloorplans ? 4 : 5}
                                            {...getRootProps()}
                                        >
                                            <Button
                                                as={NavLink}
                                                className={styles.floorplansButton}
                                                to={generateOrganizationEditLocalityFloorplanMappingPath(
                                                    organization.id,
                                                    localityId,
                                                    Number(head(Object.keys(locality.floorplans)!))
                                                )}
                                                variant="secondary"
                                            >
                                                <FontAwesomeIcon icon={faMapPin} />
                                                {t('heading.mapToFloorplan', 'Map to floor plan')}
                                            </Button>
                                        </Col>
                                        {containsFloorplans && (
                                            <>
                                                <input {...getInputProps()} />
                                                <IconButton
                                                    className={styles.uploadButton}
                                                    icon={faUpload}
                                                    tooltipPosition="right-end"
                                                    tooltipText={t(
                                                        'floorplans.uploadAdditional',
                                                        'Upload additional floor plans'
                                                    )}
                                                    onClick={open}
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                                {isEmpty(locality.floorplans) && (
                                    <>
                                        <Col md={6}>
                                            <span className="text-muted">
                                                {t('form.noFloorplanAvailable', 'No floorplan available')}
                                            </span>
                                        </Col>
                                        <Col className={styles.actionsColumn} md={4} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Button
                                                className={styles.floorplansButton}
                                                variant="secondary"
                                                onClick={open}
                                            >
                                                <FloorplanIcon />
                                                {t('button.uploadFloorplan', 'Upload floor plan')}
                                            </Button>
                                        </Col>
                                    </>
                                )}
                            </>
                        )}
                    </Form.Group>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const LocationRow = ({
    location,
    setGeoLocation,
}: {
    location?: Location
    setGeoLocation: (location?: Location | undefined) => void
}) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row} className={styles.row}>
            <Form.Label column={true} sm={2}>
                {t('form.location', 'Location')}
                <TooltipIcon>
                    {t(
                        'tooltip.locationWeather',
                        'Enter location if you would like to integrate weather data into footfall'
                    )}
                </TooltipIcon>
            </Form.Label>
            <Col sm={10}>
                <PlacePicker defaultValue={location} onChange={setGeoLocation} />
            </Col>
        </Form.Group>
    )
}

const RealtimeOccupancyRow = ({
    onRealtimeOccupancyToggle,
    isRealTimeOccupancyEnabled,
}: {
    isRealTimeOccupancyEnabled: boolean
    onRealtimeOccupancyToggle: (isRealTimeOccupancyEnabled: boolean) => void
}) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row} className={styles.row}>
            <Form.Label column={true} md={5} sm={12}>
                {t('form.localityRealTimeOccupancy', 'Locality real-time occupancy')}
                <TooltipIcon>
                    {t(
                        'tooltip.staySafeTooltip',
                        'Stay Safe - When enabled and visit boundaries are set correctly, you will be able to monitor real-time occupancy in this locality via Stay Safe screen on real-time page.'
                    )}
                </TooltipIcon>
            </Form.Label>
            <Col>
                <ToggleButton
                    leftToggle={{
                        toggleValue: '1',
                        toggleText: t('button.enabled', 'Enabled'),
                    }}
                    rightToggle={{
                        toggleValue: '',
                        toggleText: t('button.disabled', 'Disabled'),
                    }}
                    toggleName="realtimeOccupancyToggle"
                    toggleValue={isRealTimeOccupancyEnabled ? '1' : ''}
                    onToggle={(value) => onRealtimeOccupancyToggle(value === '1')}
                />
            </Col>
        </Form.Group>
    )
}

const FPRealtimeOccupancyRow = ({
    onFloorplanOccupancyToggle,
    isFloorplanOccupancyEnabled,
}: {
    onFloorplanOccupancyToggle: (isFloorplanOccupancyToggle: boolean) => void
    isFloorplanOccupancyEnabled: boolean
}) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row} className={styles.row}>
            <Form.Label column={true} md={5} sm={12}>
                {t('form.FPRealTimeOccupancy', 'Floor plan real-time occupancy')}
                <TooltipIcon>
                    {t(
                        'tooltip.staySafeTooltip',
                        'Stay Safe Floorplan - When enabled and devices mapped to floorplan correctly, you will be able to monitor real-time occupancy in this locality directly on the floor plan on real-time page.'
                    )}
                </TooltipIcon>
            </Form.Label>
            <Col>
                <ToggleButton
                    leftToggle={{
                        toggleValue: '1',
                        toggleText: t('button.enabled', 'Enabled'),
                    }}
                    rightToggle={{
                        toggleValue: '',
                        toggleText: t('button.disabled', 'Disabled'),
                    }}
                    toggleName="fpRealtimeOccupancyToggle"
                    toggleValue={isFloorplanOccupancyEnabled ? '1' : ''}
                    onToggle={(value) => onFloorplanOccupancyToggle(value === '1')}
                />
            </Col>
        </Form.Group>
    )
}

const ScenesRow = ({
    organizationId,
    localityId,
    organizationScenes,
    onScenesUpdate,
    selectedScenes,
}: {
    localityId: number | undefined
    onScenesUpdate: (ids: Array<string>) => void
    organizationScenes: Array<SceneResponse>
    organizationId: number
    selectedScenes: Array<SceneResponse>
}) => {
    const { t } = useTranslation()

    const selectableScenes = Object.fromEntries(organizationScenes.map(({ id, label }) => [id, label]))

    const TokenWithLink: React.FC<TokenProps & { tokenId?: number }> = useCallback(
        ({ disabled, onRemove, children, tokenId }) => (
            <Token key={String(tokenId)} disabled={disabled} onRemove={onRemove}>
                <Link to={generateOrganizationScenesPath(organizationId, tokenId ?? -1)}>{children}</Link>
            </Token>
        ),
        [localityId, organizationId]
    )

    return (
        <Form.Group as={Row} className={classNames(styles.badgePickerContainer, styles.row)}>
            <Col className={styles.label} md={2} sm={12}>
                {t('table.scenes', 'Scenes')}
            </Col>
            <Col md={10} sm={12}>
                <MultiSelectBox
                    choices={selectableScenes}
                    disabled={isEmpty(organizationScenes)}
                    placeholder={
                        isEmpty(selectableScenes)
                            ? t('others.noScenesCreated', 'No scenes created')
                            : t('others.noScenesSelected', 'No scenes selected')
                    }
                    selection={selectedScenes.map((d) => d.id.toString())}
                    tokenRenderer={TokenWithLink}
                    displayId
                    onSelect={onScenesUpdate}
                />
            </Col>
        </Form.Group>
    )
}

const UsersRow = ({
    organizationUsers,
    onUsersUpdate,
    selectedUsers,
    organizationId,
}: {
    organizationUsers: Array<UserResponse>
    onUsersUpdate: (ids: Array<string>) => void
    selectedUsers: Array<UserResponse>
    organizationId: number
}) => {
    const { t } = useTranslation()

    const selectableUsers = Object.fromEntries(organizationUsers.map(({ id, email }) => [id, email]))

    const UserLinkBadge: React.FC<TokenProps & { tokenId?: number }> = useCallback(
        ({ disabled, onRemove, children, tokenId }) => (
            <Token key={String(tokenId)} disabled={disabled} onRemove={onRemove}>
                <Link to={generateOrganizationEditUserPath(tokenId ?? -1, organizationId)}>{children}</Link>
            </Token>
        ),
        [organizationId]
    )

    return (
        <Form.Group as={Row} className={classNames(styles.badgePickerContainer, styles.row)}>
            <Form.Label column={true} md={2} sm={12}>
                {t('form.users', 'Users')}
            </Form.Label>
            <Col md={10} sm={12}>
                <MultiSelectBox
                    choices={selectableUsers}
                    disabled={isEmpty(selectableUsers)}
                    placeholder={
                        isEmpty(selectableUsers)
                            ? t('others.noUsersToSelect', 'No users to select')
                            : t('others.noUsersSelected', 'No users selected')
                    }
                    selection={selectedUsers.map((u) => u.id.toString())}
                    tokenRenderer={UserLinkBadge}
                    onSelect={onUsersUpdate}
                />
            </Col>
        </Form.Group>
    )
}

const OpeningHoursRow = ({
    locality,
    openingHours,
    copyOpeningHoursButton,
    organizationId,
    openingHoursUpdateStatus,
    onOpeningHoursUpdate,
}: {
    locality: LocalityResponse
    openingHours: OpeningHours | undefined
    copyOpeningHoursButton?: JSX.Element
    organizationId: number
    onOpeningHoursUpdate: (data: LocalityModel) => void
    openingHoursUpdateStatus: MutationStatus
}) => {
    const { t } = useTranslation()
    const history = useHistory()

    const isModalOpen = Boolean(useRouteMatch(ORGANIZATION_LOCALITY_EDIT_OPENING_HOURS_PATH))

    const getShortDayName = (day: string) => Object.values(DaysInWeekShortened).find((weekDay) => weekDay === day)

    const aggregateHours = openingHours
        ? mapKeys(aggregateWeekdaysByOpeningHours(openingHours), (_, key) =>
              key
                  .split('-')
                  .map((day) => {
                      const shortDayName = getShortDayName(day)

                      return shortDayName ? displayTranslatedDayInWeek(shortDayName, t) : day
                  })
                  .join(` - `)
          )
        : undefined

    const handleOpenModal = () =>
        history.push(generateOrganizationsEditLocalitiesOpeningHoursPath(organizationId, locality.id))

    const handleCloseModal = () => history.push(generateOrganizationsEditLocalitiesPath(organizationId, locality.id))

    const containsOpeningHours = Object.values(openingHours || {}).some((hours) => hours.length > 0)

    const updateAndCloseModal = (data: LocalityModel) => {
        onOpeningHoursUpdate(data)
        handleCloseModal()
    }

    return !locality.id ? null : (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.openingHoursLabel} md={2}>
                {t('tab.openingHours', 'Opening hours')}
            </Col>
            {!containsOpeningHours && copyOpeningHoursButton ? (
                <>
                    <Col className={styles.actionsColumn} md={7}>
                        {copyOpeningHoursButton}
                    </Col>
                    <Col className={styles.actionsColumn} md={3} sm={12}>
                        <LocalityOpeningHoursModal
                            isOpeningHoursModalOpen={isModalOpen}
                            locality={locality}
                            openingHoursUpdateStatus={openingHoursUpdateStatus}
                            onOpeningHoursModalClose={handleCloseModal}
                            onOpeningHoursModalOpen={handleOpenModal}
                            onOpeningHoursUpdate={updateAndCloseModal}
                        />
                    </Col>
                </>
            ) : aggregateHours && containsOpeningHours ? (
                <>
                    <Col md={7}>
                        <div className={styles.openingHoursGrid}>
                            {Object.entries(aggregateHours).map(([days, entries], i) => (
                                <React.Fragment key={i}>
                                    <div
                                        className={classNames({
                                            [styles.openingHoursDayCentered]:
                                                Object.entries(aggregateHours).length === 1,
                                        })}
                                    >
                                        {days}
                                    </div>
                                    <div>
                                        {entries &&
                                            entries.map(({ openingTime, closingTime }, index) => (
                                                <div key={index}>{formatOpeningHours(openingTime, closingTime)}</div>
                                            ))}
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </Col>
                    <Col className={styles.actionsColumn} md={3} sm={12}>
                        <LocalityOpeningHoursModal
                            isOpeningHoursModalOpen={isModalOpen}
                            locality={locality}
                            openingHoursUpdateStatus={openingHoursUpdateStatus}
                            onOpeningHoursModalClose={handleCloseModal}
                            onOpeningHoursModalOpen={handleOpenModal}
                            onOpeningHoursUpdate={updateAndCloseModal}
                        />
                    </Col>
                </>
            ) : null}
        </Form.Group>
    )
}

const ActionsRow = ({
    isSceneDescriptionModalOpen,
    localityId,
    onLocalityDelete,
    localityName,
    localityScenes,
    locality,
    localitySceneDescriptions,
    organization,
    actionButtons,
}: {
    isSceneDescriptionModalOpen: boolean
    locality: LocalityResponse
    localityId: number
    onLocalityDelete: ({ organizationId, localityId }: { organizationId: number; localityId: number }) => void
    localityName: string
    localityScenes: Array<SceneResponse>
    organization: OrganizationResponse
    profile: UseQueryResult<ProfileResponse>
    localitySceneDescriptions?: Dictionary<SceneDescription>
    actionButtons?: JSX.Element
}) => {
    const history = useHistory()
    const { t } = useTranslation()
    const notify = useNotify()

    const { mutate: recalculateLocalityStatistics, status: recalculateLocalityStatisticsState } = useMutation(
        backgroundJobsApi.scheduleStatisticsPopulation,
        {
            onSuccess: () => {
                notify({
                    variant: 'success',
                    title: t('notification.success', 'Success'),
                    content: t(
                        'notification.localityStatisticsRecalculationScheduledSuccessfully',
                        'Recalculation for locality {{ localityName }} scheduled successfully.',
                        { localityName: locality.name }
                    ),
                    timeoutSeconds: 3,
                })
            },
            onError: () => {
                notify({
                    title: t('notification.error', 'Error'),
                    content: t(
                        'notification.localityStatisticsRecalculationFailed',
                        'Something went wrong. Recalculation for locality {{ localityName }} could not be scheduled.',
                        { localityName: locality.name }
                    ),
                    variant: 'danger',
                })
            },
        }
    )

    const handleOpenModal = () =>
        history.push(generateOrganizationsEditLocalitiesPath(organization.id, locality.id, localityScenes[0].id))

    const handleCloseModal = () => history.push(generateOrganizationsEditLocalitiesPath(organization.id, locality.id))

    const handleLocalityRecalculate = () =>
        recalculateLocalityStatistics({
            body: {
                startingFrom: locality.createdAt,
                endingAt: DateTime.utc().toISO(),
                localityIds: [locality.id],
                force: true,
            },
        })

    const determineSelectScenePath = (sceneId: number) =>
        generateOrganizationsEditLocalitiesPath(organization.id, locality.id, sceneId)

    const isDescribeSceneModalDisabled = isEmpty(localityScenes)

    return (
        <Form.Group as={Row} className={styles.actionsRow}>
            <Col className={styles.label} md={2} sm={12}>
                {t('table.actions', 'Actions')}
            </Col>
            <Col className={styles.actionsColumn} md={10} sm={12}>
                {actionButtons}
                {localitySceneDescriptions && (
                    <>
                        <DescribeSceneTabModal
                            determineSelectScenePath={determineSelectScenePath}
                            isDescribeSceneTabModalDisabled={isDescribeSceneModalDisabled}
                            isDescribeSceneTabModalOpen={isSceneDescriptionModalOpen}
                            organization={organization}
                            sceneDescriptions={localitySceneDescriptions}
                            scenes={localityScenes}
                            selectedLocality={locality}
                            onDescribeSceneTabModalClose={handleCloseModal}
                            onDescribeSceneTabModalOpen={handleOpenModal}
                        />
                        <PopConfirm
                            cancelButtonVariant="secondary"
                            confirmButtonVariant="danger"
                            confirmMessage={t(
                                'others.recalculateLocality',
                                `Are you sure you want to recalculate {{ localityName }} statistics (you might lose historical data)?`,
                                { localityName: locality.name }
                            )}
                            placement="top"
                            onConfirm={handleLocalityRecalculate}
                        >
                            <Button
                                disabled={recalculateLocalityStatisticsState !== 'idle' || isEmpty(localityScenes)}
                                variant="secondary"
                            >
                                <FontAwesomeIcon icon={faSync} /> {t('button.recalculateData', 'Recalculate data')}
                            </Button>
                        </PopConfirm>
                    </>
                )}

                <PopConfirm
                    cancelButtonVariant="secondary"
                    confirmButtonVariant="danger"
                    confirmMessage={t(
                        'others.deleteLocality',
                        `Are you sure you want to delete locality {{ value }}?`,
                        { value: localityName }
                    )}
                    onConfirm={() => {
                        onLocalityDelete({ organizationId: organization.id, localityId })
                    }}
                >
                    <Button variant="danger">
                        <FontAwesomeIcon icon={faTrash} /> {t('button.delete', 'Delete')}
                    </Button>
                </PopConfirm>
            </Col>
        </Form.Group>
    )
}

type LocalitiesEditFormProps = {
    copyOpeningHoursButton?: JSX.Element
    errors?: LocalitiesEditFormErrors
    localityToEdit: LocalitiesEditData
    localityCall: UseQueryResult<LocalityResponse>
    localityLabelsCall: UseQueryResult<LocalityNameListResponse>
    localityOpeningHoursCall: UseQueryResult<LocalityOpeningHoursListResponse>
    localitySceneDescriptionsCall: UseQueryResult<Dictionary<SceneDescription>>
    organizationUsers: Array<UserResponse>
    organization: OrganizationResponse
    organizationScenes: Array<SceneResponse>
    localityScenes: Array<SceneResponse>
    selectedScenes: Array<SceneResponse>
    selectedUsers: Array<UserResponse>
    onErrorsChanged: (errors: LocalitiesEditFormErrors) => void
    onLocalityUpdate: (updatedLocality: Partial<LocalitiesEditData>) => void
    onLocalityDelete: ({ organizationId, localityId }: { organizationId: number; localityId: number }) => void
    onScenesSelect: (ids: string[]) => void
    onUsersSelect: (ids: string[]) => void
    onLocationSelect: (location?: Location | undefined) => void
    onRealtimeOccupancyToggle: (isRealTimeOccupancyEnabled: boolean) => void
    onFloorplanOccupancyToggle: (isFloorplanOccupancyToggle: boolean) => void
    onOpeningHoursUpdate: (data: LocalityModel) => void
    actionButtons?: JSX.Element
    geoLocation: Location | undefined
    isFloorplanOccupancyEnabled: boolean
    isRealTimeOccupancyEnabled: boolean
    openingHoursUpdateStatus: MutationStatus
}

const LocalitiesTabForm: React.FC<LocalitiesEditFormProps> = ({
    copyOpeningHoursButton,
    errors,
    localityToEdit,
    localityCall,
    localityLabelsCall,
    localityOpeningHoursCall,
    localitySceneDescriptionsCall,
    organization,
    organizationUsers,
    organizationScenes,
    localityScenes,
    onLocalityUpdate,
    onErrorsChanged,
    onLocalityDelete,
    onScenesSelect,
    onUsersSelect,
    onLocationSelect,
    onRealtimeOccupancyToggle,
    onFloorplanOccupancyToggle,
    onOpeningHoursUpdate,
    isFloorplanOccupancyEnabled,
    isRealTimeOccupancyEnabled,
    selectedScenes,
    selectedUsers,
    actionButtons,
    geoLocation,
    openingHoursUpdateStatus,
}) => {
    const { t } = useTranslation()
    const profileCall = useProfile()

    const [nameTouched, setNameTouched] = useState(false)

    const { locality } = localityToEdit
    const { id: organizationId } = organization
    const defaultErrors = { name: defaultErrorEntry }

    const localityId = isExistingLocality(locality) ? locality.id : undefined
    const isModalOpen = Boolean(useRouteMatch(ORGANIZATION_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH))

    useEffect(() => {
        onErrorsChanged({
            name: {
                isTouched: nameTouched,
                isInvalid: isEmpty(locality.name),
            },
        })
    }, [locality.name, nameTouched])

    const handleNameUpdate = (newName: string) => {
        setNameTouched(true)
        onLocalityUpdate({ ...localityToEdit, locality: { ...locality, name: newName } })
    }

    const handleLocalityLabelUpdate = (newLabels: Array<string>) => {
        onLocalityUpdate({ ...localityToEdit, locality: { ...locality, labels: newLabels } })
    }

    const actionRowCalls = mergeQueryResults(localityCall, localitySceneDescriptionsCall)

    const labelList =
        uniq(
            stableSort(
                flatMap(
                    localityLabelsCall.data?.localities.filter(({ organizationId: orgId }) => orgId === organizationId),
                    ({ labels }) => labels
                ),
                (a, b) => a.localeCompare(b)
            )
        ) || []

    const labelChoices = !isEmpty(labelList) ? Object.fromEntries(labelList.map((label) => [label, label])) : {}

    const apiCalls = mergeQueryResults(localityCall, localityOpeningHoursCall)

    return (
        <Form className={styles.localitiesEditForm}>
            <NameRow
                errors={errors ?? defaultErrors}
                isNewLocality={localityId === undefined}
                name={locality.name}
                onUpdate={handleNameUpdate}
            />
            <LabelRow labelChoices={labelChoices} labels={locality.labels} onUpdate={handleLocalityLabelUpdate} />
            <LocationRow location={geoLocation} setGeoLocation={onLocationSelect} />
            <LegacyLoadingWrapper
                placeholder={
                    <Form.Group as={Row} className={styles.row}>
                        <Col className={styles.openingHoursLabel} md={2}>
                            {t('tab.openingHours', 'Opening hours')}
                        </Col>
                        <Col>
                            <span className="text-muted">{t('others.Loading', 'Loading')}</span>
                        </Col>
                    </Form.Group>
                }
                request={apiCalls}
            >
                {([locality]) => (
                    <OpeningHoursRow
                        copyOpeningHoursButton={copyOpeningHoursButton}
                        locality={locality}
                        openingHours={localityToEdit.openingHours}
                        openingHoursUpdateStatus={openingHoursUpdateStatus}
                        organizationId={organizationId}
                        onOpeningHoursUpdate={onOpeningHoursUpdate}
                    />
                )}
            </LegacyLoadingWrapper>
            <ScenesRow
                localityId={localityId}
                organizationId={organizationId}
                organizationScenes={organizationScenes}
                selectedScenes={selectedScenes}
                onScenesUpdate={onScenesSelect}
            />
            <UsersRow
                organizationId={organizationId}
                organizationUsers={organizationUsers}
                selectedUsers={selectedUsers}
                onUsersUpdate={onUsersSelect}
            />
            {localityId && <FloorplanRow localityId={localityId} organization={organization} />}
            {localityId ? (
                <LegacyLoadingWrapper
                    placeholder={
                        <Form.Group as={Row} className={styles.actionsRow}>
                            <Col className={styles.label} md={2} sm={12}>
                                {t('table.actions', 'Actions')}
                            </Col>
                            <Col>
                                <span className="text-muted">{t('others.Loading', 'Loading')}</span>
                            </Col>
                            <ControlledModal
                                className={styles.modal}
                                control={null}
                                show={isModalOpen}
                                centered
                                onHide={noop}
                            >
                                <div className={styles.placeholder}>
                                    <LayoutHeading heading={t('tab.describeScene', 'Describe scene')} />
                                    <LoadingSpinner bare />
                                </div>
                            </ControlledModal>
                        </Form.Group>
                    }
                    request={actionRowCalls}
                >
                    {([locality, localitySceneDescriptions]) => (
                        <ActionsRow
                            actionButtons={actionButtons}
                            isSceneDescriptionModalOpen={isModalOpen}
                            locality={locality}
                            localityId={localityId}
                            localityName={locality.name}
                            localitySceneDescriptions={localitySceneDescriptions}
                            localityScenes={localityScenes}
                            organization={organization}
                            profile={profileCall}
                            onLocalityDelete={onLocalityDelete}
                        />
                    )}
                </LegacyLoadingWrapper>
            ) : (
                actionButtons
            )}
            <RoleChecker whitelist={[Role.Administrator]}>
                <>
                    <Form.Group as={Row} className={styles.row}>
                        <Col>
                            <span>
                                <strong>{t('others.moduleSettings', 'Module Settings')}</strong>
                            </span>
                        </Col>
                    </Form.Group>
                    <RealtimeOccupancyRow
                        isRealTimeOccupancyEnabled={isRealTimeOccupancyEnabled}
                        onRealtimeOccupancyToggle={onRealtimeOccupancyToggle}
                    />
                    <FPRealtimeOccupancyRow
                        isFloorplanOccupancyEnabled={isFloorplanOccupancyEnabled}
                        onFloorplanOccupancyToggle={onFloorplanOccupancyToggle}
                    />
                </>
            </RoleChecker>
        </Form>
    )
}

export default LocalitiesTabForm
